import Vue from "vue";
import VueI18n from "vue-i18n";
// import messages from './langs'
Vue.use(VueI18n);

import en from "../i18n/langs/en";
import ja from "../i18n/langs/ja";
import tw from "../i18n/langs/tw";
// 取得預設語系
const locale = window.g.language || "tw";

// 建立 VueI18n 實體
const i18n = new VueI18n({
  locale: locale,
  messages: { tw, en, ja }
});

export default i18n;
