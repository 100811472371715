<template>
  <div id="room">
    <Header></Header>
    <ul class="content" v-if="roomList">
      <router-link
        tag="li"
        class="roomlist"
        v-for="room in roomList"
        :to="{ name: 'RoomDetail', params: { roomId: room.operationRoom.id } }"
        :key="room.operationRoom.id"
        :class="{ none: room.operationRoom.check_status == null }"
      >
        <!-- 側邊欄 -->
        <div class="side-left">
          <div class="state">
            <div
              class="qua"
              :class="{
                TSI: room.operationRoom.check_status == '1', // 待安排
                TTO: room.operationRoom.check_status == '2', // 等候病患
                TCI: room.operationRoom.check_status == '3', // 進入術房
                TSO: room.operationRoom.check_status == '4', // 麻醉前導
                status5: room.operationRoom.check_status == '5', // 已劃刀
                status6: room.operationRoom.check_status == '6', // 關傷口前
                status7: room.operationRoom.check_status == '7', // 關傷口後
                status8: room.operationRoom.check_status == '8', // 離開術房
                statusW: room.operationRoom.check_status == 'W', // 未開始
                statusP:
                  room.operationRoom.check_status == 'WR' || // 等候室
                  room.operationRoom.check_status == 'IR' || // 入OR
                  room.operationRoom.check_status == 'SI' || // Sign in
                  room.operationRoom.check_status == 'TO' || // Time out
                  room.operationRoom.check_status == 'SO', // Sign out
                statusC: room.operationRoom.check_status == 'C', // 取消
                statusY: room.operationRoom.check_status == 'Y' // 完成
              }"
            >
              <p>{{ room.operationRoom.check_status | operationStatusMsg }}</p>
            </div>
            <div
              class="tri"
              :class="{
                triTSI: room.operationRoom.check_status == '1', // 待安排
                triTTO: room.operationRoom.check_status == '2', // 等候病患
                triTCI: room.operationRoom.check_status == '3', // Check in
                triTSO: room.operationRoom.check_status == '4', // 麻醉前導
                triStatus5: room.operationRoom.check_status == '5', // 已劃刀
                triStatus6: room.operationRoom.check_status == '6', // 關傷口前
                triStatus7: room.operationRoom.check_status == '7', // 關傷口後
                triStatus8: room.operationRoom.check_status == '8', // 離開術房
                triStatusW: room.operationRoom.check_status == 'W', // 未開始
                triStatusP:
                  room.operationRoom.check_status == 'WR' || // 等候室
                  room.operationRoom.check_status == 'IR' || // 入OR
                  room.operationRoom.check_status == 'SI' || // Sign in
                  room.operationRoom.check_status == 'TO' || // Time out
                  room.operationRoom.check_status == 'SO', // Sign out
                triStatusC: room.operationRoom.check_status == 'C', // 取消
                triStatusY: room.operationRoom.check_status == 'Y' // 完成
              }"
            ></div>
          </div>
          <div class="mechine">
            <ul>
              <div v-if="room.eqList">
                {{ room.eqList[0] && room.eqList[0].equiment }}
              </div>
              <div
                class="timeline"
                v-for="(knife_sequence, index) in room.knife_sequence"
                :key="index"
              >
                <div v-if="index < 5">
                  <span>{{ knife_sequence.doctor_name }}</span>
                  <span>{{ knife_sequence.time }}</span>
                  <div
                    v-if="index == 4 && room.knife_sequence.length > 5"
                    style="text-align:center"
                  >
                    <span style="writing-mode: vertical-lr;">...</span>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="side-right">
          <div class="info-one">
            <div class="room-num">
              {{ room.operationRoom.operation_room_num }}
            </div>
            <!-- 這邊加v-if="room.operationInfo !== null"是因為測試資料不是每個都有回傳operationInfo -->
            <div v-if="room.operationInfo" class="name">
              {{ room.operationInfo.patient_name }}
            </div>
          </div>
          <div class="info-two">
            <div class="top">
              <div style="width:25%">
                <!-- 主刀 -->
                <p class="title">{{ $t("room.surgeon") }}</p>
                <!-- 這邊加v-if="room.operationInfo !== null"是因為測試資料不是每個都有回傳operationInfo -->
                <p v-if="room.operationInfo !== null" class="content-name">
                  {{ room.operationInfo.doctor_name }}
                </p>
              </div>
              <div style="width: 40%;">
                <!-- 科別 -->
                <p class="title">{{ $t("room.departmentName") }}</p>
                <!-- 這邊加v-if="room.operationInfo !== null"是因為測試資料不是每個都有回傳operationInfo -->
                <p v-if="room.operationInfo !== null" class="content-name">
                  {{ room.operationInfo.department_name }}
                </p>
              </div>
              <div style="width: 35%;">
                <!-- 刀種 -->
                <p class="title">{{ $t("room.operationType") }}</p>
                <!-- <p v-if="room.operationInfo !== null" class="status">{{ room.operationInfo.operationStatus | statusMsg }}</p> -->
                <p v-if="room.operationInfo !== null" class="content-name">
                  {{ room.operationInfo.operation_type | operationTypeMsg }}
                </p>
              </div>
            </div>
            <div class="bottom">
              <div style="width:100%">
                <!-- 術式 -->
                <p class="title">{{ $t("room.surgical") }}</p>
                <!-- 這邊加v-if="room.operationInfo !== null"是因為測試資料不是每個都有回傳operationInfo -->
                <p v-if="room.operationInfo !== null" class="content-name">
                  {{ room.operationInfo.operation_name }}
                </p>
              </div>
            </div>
          </div>
          <div class="info-three">
            <div class="hands">
              <span>{{ $t("room.scrubNurse") }}</span>
              <span v-if="room.help !== null">{{
                helpMsg(room.help.help11, room.help.help12)
              }}</span>
            </div>
            <div class="fluid">
              <span>{{ $t("room.flow") }}</span>
              <span v-if="room.help !== null">{{
                helpMsg(room.help.help01, room.help.help02)
              }}</span>
            </div>
            <div class="anaes">
              <span>{{ $t("room.anaesDoctor") }}</span>
              <span v-if="room.help !== null">{{
                helpMsg(room.help.help41, room.help.help42)
              }}</span>
            </div>
            <!-- 接四個時間 -->
            <!--             <div class="loading" >
              <img src="../assets/room/time@2x.png" alt="">
                這邊加v-if="room.operationInfo !== null"是因為測試資料不是每個都有回傳operationInfo
              <div class="total" v-if="room.operationInfo !== null" :class= "{ pencilNone: actualWidth(room.operationInfo) == '' }">
                <div class="actual" v-if="room.operationInfo !== null"  :style="'width:'+ (actualWidth(room.operationInfo) ? actualWidth(room.operationInfo) : 0) + '%'" :class="{ overtime: (actualWidth(room.operationInfo) ? actualWidth(room.operationInfo) : 0)>100}"  ></div>
              </div>
              <div :class= "{ pencilNone: actualWidth(room.operationInfo) == '' }" v-if="room.operationInfo !== null" > {{ actualWidth(room.operationInfo) }}%</div>
            </div> -->

            <!--             接persentage
            <div class="loading">
              <img src="../assets/room/time@2x.png" alt="">
              <div class="total" v-if="room.operationInfo !== null">
                <div class="actual" v-if="room.operationInfo !== null" :style="'width:'+ (room.opPersentage ? room.opPersentage : 0) + '%'"></div>
              </div>
              <div v-if="room.operationInfo !== null" >{{room.opPersentage ? room.opPersstatusWentage : 0}}%</div>
            </div> -->
            <div class="loading">
              <div
                class="total"
                v-if="room.operationRoom.check_status !== null"
              >
                <div
                  style="margin-top:6px;text-align: center;color:red;z-index:0;position: fixed;"
                >
                  {{ room.operationRoom.check_status | operationStatusMsg }}
                </div>
                <div
                  class="actual"
                  v-if="room.operationRoom.check_status !== null"
                  :style="
                    'width:' + opPersentage(room.operationRoom.check_status)
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </ul>
    <ul class="pagination_icon">
      <li
        v-for="index in pageOption.allPages"
        :key="index"
        :class="{ selected: pageOption.pageSize === index }"
      ></li>
    </ul>
    <Footer
      :tabIndex="tabIndex"
      :pageOption="pageOption"
      :pageOptions="pageOptions"
      :retrievalState="retrievalState"
      @childSearchContent="searchContentClick"
      ref="child"
    ></Footer>
    <ul class="searchContent" v-if="searchContent">
      <li class="operation-type">
        <p>{{ $t("room.operationType") }}</p>
        <ol>
          <li
            v-for="(item, i) in operationTypeList"
            :key="i"
            @click="condition('operationType', item.code)"
            :class="{ selected: nowOperationTypeList.includes(item.code) }"
          >
            {{ item.name }}
          </li>
        </ol>
      </li>
      <li class="category">
        <p>{{ $t("room.departmentName") }}</p>
        <ol>
          <li
            v-for="(item, i) in categoryList"
            :key="i"
            @click="condition('category', item)"
            :class="{ selected: nowCategoryList.includes(item) }"
          >
            {{ item }}
          </li>
        </ol>
      </li>
      <li class="operation-room-id">
        <p>{{ $t("room.operationRoomID") }}</p>
        <ol>
          <li
            v-for="(item, i) in operationRoomIDList"
            :key="i"
            @click="condition('operationRoomID', item)"
            :class="{ selected: nowOperationRoomIDList.includes(item) }"
          >
            {{ item }}
          </li>
        </ol>
      </li>
      <li class="button">
        <!--         <div class="clear" @click="clearClick()">
          <img src="../assets/footer/clear.png" alt="">
          <span>清除</span>
        </div> -->
        <div class="enter" @click="searchClick()">
          <img src="../assets/footer/enter.png" alt="" />
          <span>{{ $t("room.determine") }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import moment from "moment";
import { api } from "@/api/api.js";
import i18n from "@/i18n/i18n";

export default {
  name: "Room",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      tabIndex: 1, // 底部tab栏的索引
      pageOption: {
        // 翻页
        allPages: 2, // 总页数
        pageSize: 1 // 当前页码
      },
      pageOptions: true,
      retrievalState: true, // 显示条件检索(footer上的按鈕)
      searchContent: false, // 显示条件检索内容（點擊按鈕會跳出/選單）
      operationTypeList: [
        { code: "1", name: i18n.t("room.regularKnife") },
        { code: "2", name: i18n.t("room.emergencyKnife") },
        { code: "3", name: i18n.t("room.sharpKnife") } // 2, 3, 4, 5
      ],
      operationRoomIDList: [
        "R01",
        "R02",
        "R03",
        "R04",
        "R05",
        "R06",
        "R07",
        "R08",
        "R09",
        "R10",
        "R11",
        "R12",
        "R13",
        "R14",
        "R15",
        "R16",
        "R17",
        "R18",
        "R19",
        "R20",
        "R21",
        "R22",
        "R23",
        "R24",
        "R25",
        "R26",
        "R27",
        "R28",
        "R29"
      ], // 手術房號
      categoryList: [
        i18n.t("room.gastroenterology"),
        i18n.t("room.generalSurgery"),
        i18n.t("room.neuroSurgery"),
        i18n.t("room.urology"),
        i18n.t("room.orthopedics"),
        i18n.t("room.plasticSurgery"),
        i18n.t("room.thoracicSurgery"),
        i18n.t("room.pediatricSurgery"),
        i18n.t("room.cardiovascularSurgery"),
        i18n.t("room.obstetricsAndGynecology"),
        i18n.t("room.dentistry"),
        i18n.t("room.oralAndMaxillofacialSurgery"),
        i18n.t("room.ophthalmology"),
        i18n.t("room.otolaryngology"),
        i18n.t("room.anesthesiaDepartment")
      ], // 检索(科别)列表
      nowCategoryList: [], // 选中检索(科别)列表
      nowOperationTypeList: [], // 選中檢所(刀種)列表
      nowOperationRoomIDList: [], // 選中檢所(刀房號)列表
      roomList: [],
      loading: null
    };
  },
  watch: {
    "pageOption.pageSize"() {
      this.whetherDilter ? this.enterClick() : this.initRoomInfo();
    },
    "pageOption.allPages"() {
      // 改变时，让当前页码等于1
      this.pageOption.pageSize = 1;
    }
  },
  methods: {
    loadingShow(isOpen = true) {
      if (isOpen) {
        this.loading = this.$loading({
          lock: true,
          text: i18n.t("room.loading"),
          background: "rgba(0, 0, 0, 0.5)",
          fullscreen: true
        });
      } else {
        this.loading.close();
      }
    },
    searchContentClick(state) {
      // 接收子元素传过来的数据
      this.searchContent = state;
    },
    async searchClick() {
      this.pageOption.pageSize = 1;
      await this.enterClick();
    },
    async enterClick() {
      this.loadingShow(true);
      this.searchContent = false;
      this.$refs.child.searchContent = false;
      this.filterFlag = true;
      var screenData = {
        // date: this.getDateStr(-1),
        date: moment().format("YYYY-MM-DD")
        // date: '2020-12-03',
      };
      if (this.nowCategoryList.length > 0) {
        screenData.department_name_list = this.nowCategoryList;
      }
      if (this.nowOperationTypeList.length > 0) {
        // 3 要傳 2, 3, 4, 5
        if (this.nowOperationTypeList.includes("3")) {
          let temp = this.nowOperationTypeList.slice(0);
          // 沒 2 補 2
          if (!this.nowOperationTypeList.includes("2")) {
            temp.push("2");
          }
          temp.push("4", "5");
          screenData.operation_type_list = temp;
        } else {
          screenData.operation_type_list = this.nowOperationTypeList;
        }
      }
      if (this.nowOperationRoomIDList.length > 0) {
        screenData.operation_room_list = this.nowOperationRoomIDList;
      }
      // 可用es6c樣板字面值（template Literal） `${api.getOperationInfoScreen} ?pageNum= ${this.pageOption.pageSize} &pageSize=8, screenData`
      await this.$axios
        .post(window.g.roomFindByParams + this.pageOption.pageSize, screenData)
        .then(
          res => {
            if (res.data.success) {
              this.roomList = res.data.result.List;
              this.pageOption.allPages = res.data.result.pages;
              this.whetherDilter = true;
            } else {
              alert(i18n.t("room.roomFindByParamAPIError"));
            }
          },
          err => {
            alert(i18n.t("room.roomFindByParamAPIError"));
          }
        );
      this.loadingShow(false);
      // // TODO TEST
      // let json = require("../testdata/roomFindByParams.json");
      // this.roomList = json.result.list
    },
    condition(type, data) {
      let nowListName = "";
      switch (type) {
        case "operationType":
          nowListName = "nowOperationTypeList";
          break;
        case "category":
          nowListName = "nowCategoryList";
          break;
        case "operationRoomID":
          nowListName = "nowOperationRoomIDList";
          break;
      }

      if (this[nowListName] !== "") {
        if (this[nowListName].includes(data)) {
          this[nowListName] = this[nowListName].filter(function(ele) {
            return ele !== data;
          });
        } else {
          this[nowListName].push(data);
        }
      }
    },
    // 用checkstatus來換成persantage
    opPersentage(data) {
      if (data == "2" || data == "WR") {
        return "10%";
      } else if (data == "3" || data == "IR") {
        return "20%";
      } else if (data == "4" || data == "SI") {
        return "40%";
      } else if (data == "5" || data == "TO") {
        return "60%";
      } else if (data == "6") {
        return "80%";
      } else if (data == "7") {
        return "90%";
      } else if (data == "8" || data == "SO") {
        return "100%";
      } else {
        return "0%";
      }
    },
    actualWidth(operationInfo) {
      // operationStart是預估時間，剛好當預估時間是空的的時候，這間手術房沒有人使用的。return 0。
      // actualWidth return 0 時 class為.total.pencilNone（這段寫在template）
      if (operationInfo.operationStart == null) {
        // 預估開始
        return 0;
      }
      // 如果有實際結束時間代表手術已結束。 公式為 預估開始與預估結束時間差異/實際開始與實際結束時間差異
      if (operationInfo.operationTrueEnd) {
        // 預估開始
        let estimateStart = operationInfo.operationStart;
        // 預估結束
        let estimateEnd = operationInfo.operationEnd;
        // 實際開始
        let trueStart = operationInfo.operationTrueStart;
        let trueEnd = operationInfo.operationTrueEnd;
        // 資料格式調整
        let start_date = moment(estimateStart, "YYYY-MM-DD HH:mm:ss");
        let end_date = moment(estimateEnd, "YYYY-MM-DD HH:mm:ss");
        let true_start_date = moment(trueStart, "YYYY-MM-DD HH:mm:ss");
        let true_end_date = moment(trueEnd, "YYYY-MM-DD HH:mm:ss");

        //秒 預估開始與預估結束時間差異
        let seconds = end_date.diff(start_date, "seconds");
        // 現在時間
        // let now = moment().toObject();
        //秒 實際開始與實際結束時間差異
        let count = -true_start_date.diff(true_end_date, "seconds");
        // 計算時間佔比
        let persent = Math.floor((count / seconds) * 100);
        return persent;
      }
      // 如果沒有有實際結束時間代表手術還沒結束 公式為 預估開始與預估結束時間差異/現實際開始與現在時間差異
      if (operationInfo.operationTrueEnd == null) {
        // 預估開始
        let estimateStart = operationInfo.operationStart;
        // 預估結束
        let estimateEnd = operationInfo.operationEnd;
        // 實際開始
        let trueStart = operationInfo.operationTrueStart;
        // 資料格式調整
        let start_date = moment(estimateStart, "YYYY-MM-DD HH:mm:ss");
        let end_date = moment(estimateEnd, "YYYY-MM-DD HH:mm:ss");
        let true_start_date = moment(trueStart, "YYYY-MM-DD HH:mm:ss");

        //秒 預估開始與預估結束時間差異
        let seconds = end_date.diff(start_date, "seconds");
        // 現在時間
        let now = moment().toObject();
        //秒 現實際開始與現在時間差異
        let count = -true_start_date.diff(now, "seconds");
        // 計算時間佔比
        let persent = Math.floor((count / seconds) * 100);
        return persent;
      }
    },
    async initRoomInfo() {
      // 获取手术信息表
      // 獲取今天日期並將格式處理成api要的樣子
      const currentDateTime = moment().format("YYYY-MM-DD");
      this.loadingShow(true);
      // const currentDateTime = "2022-12-24"
      var data = {
        date: currentDateTime
        // date: this.getDateStr(0),
      };
      await this.$axios
        .post(window.g.roomFindByParams + this.pageOption.pageSize, data)
        .then(
          res => {
            if (res.data.success) {
              this.roomList = res.data.result.List;
              // opPersentage需轉sting to number
              // this.roomList = res.data.result.list.map(item=>{
              //   item.opPersentage=Number(item.opPersentage)
              //     return item;
              // });
              // console.log(this.roomList)
              this.pageOption.allPages = res.data.result.pages;
              // if (this.roomList.operationInfo !== null) {
              //   console.log(a)
              // }
            } else {
              alert(i18n.t("room.getRoomFindByParamAPIError"));
            }
          },
          err => {
            alert(i18n.t("room.getRoomFindByParamAPIError"));
          }
        );
      this.loadingShow(false);
      // // TODO TEST
      // let json = require("../testdata/roomFindByParams.json");
      // this.roomList = json.result.list.slice(0, 4)

      // this.pageOption.allPages = json.result.pages
    },

    // touchMove(){
    //   const slider = document.querySelector('.content')
    //   let firstPosition = 0
    //   let secondPosition = 0
    //   slider.addEventListener('touchstart', (e) =>{
    //     firstPosition = e.targetTouches[0].clientX
    //   })
    //   slider.addEventListener('touchend', (e2) =>{
    //     secondPosition = e2.changedTouches[0].clientX
    //     console.log(firstPosition,secondPosition)
    //   })
    // },
    // clickMove(){
    //   const slider = document.querySelector('.content')
    //   let firstPosition = 0
    //   let secondPosition = 0
    //   slider.addEventListener('mousedown', (e) =>{
    //     firstPosition = e.clientX
    //   })
    //   slider.addEventListener('mouseup', (e2) =>{
    //     secondPosition = e2.clientX
    //     console.log(firstPosition,secondPosition)
    //   })
    // }
    helpMsg(nameFirst, nameSecond) {
      if (nameSecond) {
        return nameFirst + "," + nameSecond;
      }
      return nameFirst;
    }
  },

  filters: {
    operationStatusMsg(status) {
      if (status == "1") {
        return i18n.t("room.toBeArranged");
      } else if (status == "2") {
        return i18n.t("room.waitingForPatients");
      } else if (status == "3") {
        return i18n.t("room.EnterOperatingRoom");
      } else if (status == "4") {
        return i18n.t("room.AnesthesiaPrelude");
      } else if (status == "5") {
        return i18n.t("room.Scored");
      } else if (status == "6") {
        return i18n.t("room.BeforeClosingWound");
      } else if (status == "7") {
        return i18n.t("room.AfterClosingWound");
      } else if (status == "8") {
        return i18n.t("room.LeaveOperatingRoom");
      } else if (status == "W") {
        return i18n.t("room.hasNotStarted");
      } else if (status == "WR") {
        return i18n.t("room.waitingRoom");
      } else if (status == "IR") {
        return i18n.t("room.enterOR");
      } else if (status == "SI") {
        return i18n.t("room.signIn");
      } else if (status == "TO") {
        return i18n.t("room.timeOut");
      } else if (status == "SO") {
        return i18n.t("room.signOut");
      } else if (status == "C") {
        return i18n.t("room.cancel");
      } else if (status == "Y") {
        return i18n.t("room.finish");
      }
    },
    operationTypeMsg(type) {
      switch (type) {
        case "1":
          return i18n.t("room.regularKnifeRow");
        case "2":
          return i18n.t("room.generalEmergencyKnife");
        case "3":
          return i18n.t("room.insertKnife");
        case "4":
          return i18n.t("room.superEmergencySurgery");
        case "5":
          return i18n.t("room.urgentKnife");
        case "6":
          return i18n.t("room.beautyKnife");
        case "X":
          return i18n.t("room.delete");
        default:
          return "";
      }
    },
    // statusMsg(status) {
    //   if (status == '01') {
    //     return '未排刀'
    //   } else if (status == '02') {
    //     return '已排刀'
    //   } else if (status == '03') {
    //     return '等候室'
    //   } else if (status == '04') {
    //     return '手術中'
    //   } else if (status == '05') {
    //     return '恢復室'
    //   } else if (status == '06') {
    //     return '回病房'
    //   } else if (status == '07') {
    //     return '回加護病房'
    //   } else if (status == '08') {
    //     return '手術完成'
    //   } else if (status == '09') {
    //     return '取消(未麻醉)'
    //   } else if (status == '10') {
    //     return '取消(已麻醉)'
    //   }
    // },

  },
  created() {
    this.initRoomInfo();
    this.timer = setInterval(() => {
      if (this.filterFlag) {
        this.enterClick();
      } else {
        this.initRoomInfo();
      }
    }, window.g.updateTime * 1000 * 60);

    // var i;
    // console.log("local storage");
    // for (i = 0; i < localStorage.length; i++)   {
    //     console.log(localStorage.key(i) + "=[" + localStorage.getItem(localStorage.key(i)) + "]");
    // }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  }, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
  // methods:{
  // actualWidth: function(){
  //   this.roomList.forEach(room =>{
  //     var estimateStart = this.roomList.operationInfo.operationStart;
  //     var estimateEnd = this.roomList.operationInfo.operationEnd;
  //     var trueStart = this.roomList.operationInfo.operationTrueStart;
  //     var start_date = moment(estimateStart,"YYYY-MM-DD HH:mm:ss");
  //     var end_date = moment(estimateEnd,"YYYY-MM-DD HH:mm:ss");
  //     var true_start_date = moment(trueStart,"YYYY-MM-DD HH:mm:ss");
  //     //秒
  //     var seconds = end_date.diff(start_date,"seconds");
  //     var DD = moment().toObject()
  //     var ter = - true_start_date.diff(DD,"seconds");
  //     var persent = Math.floor(ter/seconds*100)
  //   })
  //   return persent
  // }
};
</script>
<style scoped lang="scss">
#room {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #efefef;
  .content {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 12px 20px 22px 20px;
    align-content: flex-start;
    height: calc(100% - 9.5% - 97px - 54px);
    // 5*3列表
    .roomlist {
      background: #ffffff;
      border-radius: 31px 26px 31px 31px;
      height: calc(97% / 2);
      width: calc(97% / 2);
      box-sizing: border-box;
      display: flex;
    }
    .roomlist:not(:nth-child(5n)) {
      margin-right: 1.5%;
      margin-bottom: 0.5%;
    }
    // 特定條件時（條件待確認）背景灰色遮罩
    .none {
      background: linear-gradient(
        rgba(213, 213, 213, 0.5),
        rgba(213, 213, 213, 0.5)
      );
    }
  }
}
@mixin state {
  display: flex;
  // 文字置中
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 31px 0 0 0px;
}
@mixin triangle {
  width: 0px;
  height: 0px;
  // 垂直置中and內縮
  margin: auto 0 auto -1%;
  border-style: solid;
  border-width: 10px 0px 10px 15px;
}
.side-left {
  height: 100%;
  width: 23%;
  color: #ffffff;
  .state {
    height: 30%;
    width: 100%;
    display: -webkit-inline-box;
    // border-bottom: none;

    // defaul 無資料
    .qua {
      @include state;
      background-color: #9d9d9d;
      border-bottom: 1px solid #9d9d9d;
    }
    .tri {
      @include triangle;
      border-color: transparent transparent transparent #9d9d9d;
    }
    // Sign-in
    .TSI {
      @include state;
      background-color: #2fb0c3;
      border-bottom: 1px solid #2fb0c3;
    }
    .triTSI {
      @include triangle;
      border-color: transparent transparent transparent #2fb0c3;
    }
    // Time out
    .TTO {
      @include state;
      background-color: #d69727;
      border-bottom: 1px solid #d69727;
    }
    .triTTO {
      @include triangle;
      border-color: transparent transparent transparent #d69727;
    }
    //Check in
    .TCI {
      @include state;
      background-color: #2e9ad3;
      border-bottom: 1px solid #2e9ad3;
    }
    .triTCI {
      @include triangle;
      border-color: transparent transparent transparent #2e9ad3;
    }
    //Sign out
    .TSO {
      @include state;
      background-color: #a31f24;
      border-bottom: 1px solid #a31f24;
    }
    .triTSO {
      @include triangle;
      border-color: transparent transparent transparent #a31f24;
    }
    .status5 {
      @include state;
      background-color: #d69727;
      border-bottom: 1px solid #d69727;
    }
    .triStatus5 {
      @include triangle;
      border-color: transparent transparent transparent #d69727;
    }
    .status6 {
      @include state;
      background-color: #463427;
      border-bottom: 1px solid #463427;
    }
    .triStatus6 {
      @include triangle;
      border-color: transparent transparent transparent #463427;
    }
    .status7 {
      @include state;
      background-color: #7575ac;
      border-bottom: 1px solid #7575ac;
    }
    .triStatus7 {
      @include triangle;
      border-color: transparent transparent transparent #7575ac;
    }
    .status8 {
      @include state;
      background-color: #d89876;
      border-bottom: 1px solid #d89876;
    }
    .triStatus8 {
      @include triangle;
      border-color: transparent transparent transparent #d89876;
    }
    .statusW {
      @include state;
      background-color: #2e608b;
      border-bottom: 1px solid #2e608b;
    }
    .triStatusW {
      @include triangle;
      border-color: transparent transparent transparent #2e608b;
    }
    .statusP {
      @include state;
      background-color: #7ec3d6;
      border-bottom: 1px solid #7ec3d6;
    }
    .triStatusP {
      @include triangle;
      border-color: transparent transparent transparent #7ec3d6;
    }
    .statusC {
      @include state;
      background-color: #d89876;
      border-bottom: 1px solid #d89876;
    }
    .triStatusC {
      @include triangle;
      border-color: transparent transparent transparent #d89876;
    }
    .statusY {
      @include state;
      background-color: #1b5b6c;
      border-bottom: 1px solid #1b5b6c;
    }
    .triStatusY {
      @include triangle;
      border-color: transparent transparent transparent #1b5b6c;
    }
    p {
      width: 70%;
      font-size: 2.25rem;
      font-weight: bold;
      font-family: Helvetica-Bold;
      text-align: center;
      color: #ffffff;
      line-height: 1.5rem;
    }
  }
  .mechine {
    height: 70%;
    width: 100%;
    background: #9d9d9d;
    border-radius: 0px 0 0 31px;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      li {
        width: 90%;
        margin-top: 25%;
        //強制不換行
        white-space: nowrap;
        //自動隱藏文字
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.25rem;
        font-family: Helvetica, Helvetica-Bold;
        font-weight: 700;
        text-align: left;
      }
    }
  }
}
.side-right {
  height: 100%;
  width: 77%;
  // 房間名稱與名字
  .info-one {
    margin: auto 0 auto 8%;
    height: 30%;
    display: flex;
    align-items: center;
    .room-num {
      margin-right: 6%;
      font-size: 3rem;
      font-family: Segoe UI, Segoe UI-Bold;
      font-weight: 700;
      text-align: left;
      color: #206a88;
      line-height: 5.4rem;
    }
    .name {
      overflow: hidden;
      text-overflow: clip;
      white-space: nowrap;
      font-size: 2.625rem;
      font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
      font-weight: 700;
      color: #206a88;
      line-height: 5.4rem;
    }
  }

  .info-two {
    // display: flex;
    height: 44%;
    border-top: 1px solid #206a88;
    margin: 0 auto;
    padding: 5px 10px 0px 20px;
    .top {
      // height: 100%;
      display: flex;
      // flex-direction: column;
      padding-top: 5px;
      box-sizing: border-box;
      .title {
        font-size: 1.3rem;
        font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
        font-weight: 700;
        color: #777776;
        line-height: 1.3125rem;
      }
      .content-name {
        font-size: 1.5rem;
        font-family: Helvetica;
        color: #206a88;
        line-height: 3rem;
        font-weight: bold;
        white-space: nowrap;
      }
    }
    .bottom {
      display: flex;
      padding-top: 15px;
      box-sizing: border-box;
      .title {
        font-size: 1.3rem;
        font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
        font-weight: 700;
        color: #777776;
        line-height: 1.3125rem;
      }
      .content-name {
        font-size: 1.5rem;
        font-family: Helvetica;
        color: #206a88;
        line-height: 3rem;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  // 刷手、流動標題
  @mixin three-title {
    font-size: 1.3rem;
    font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
    font-weight: 700;
    color: #777776;
    line-height: 1.3125rem;
    margin-right: 3px;
  }
  @mixin three-content {
    font-size: 1.3rem;
    font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
    font-weight: 700;
    color: #206a88;
    margin-left: 1rem;
    line-height: 1.5rem;
  }
  .info-three {
    display: flex;
    flex-wrap: wrap;
    height: 26%;
    width: 100%;
    border-top: 1px solid #206a88;
    padding-top: 5px;
    padding: 5px 10px 0px 23px;
    box-sizing: border-box;
    .hands {
      width: 33%;
      display: flex;
      align-items: center;
      > :nth-child(1) {
        @include three-title;
      }
      > :nth-child(2) {
        @include three-content;
      }
    }
    .fluid {
      width: 33%;
      display: flex;
      align-items: center;
      > :nth-child(1) {
        @include three-title;
      }
      > :nth-child(2) {
        @include three-content;
      }
    }
    .anaes {
      width: 33%;
      display: flex;
      align-items: center;
      > :nth-child(1) {
        @include three-title;
      }
      > :nth-child(2) {
        @include three-content;
      }
    }
    .loading {
      width: 100%;
      height: 50%;
      display: flex;
      align-items: center;
      > div {
        width: 40px;
        height: 60%;
        font-size: 1.25rem;
        font-family: Helvetica-Bold;
        color: #d56b18;
        // line-height: 1.5rem;
      }
      img {
        height: 50%;
        margin-right: 10%;
      }
      .total {
        width: 82%;
        overflow: hidden;
        border: 1px solid #e56300;
        margin-right: 8px;
        display: flex;
        justify-content: space-around;
      }
      .pencilNone {
        display: none;
      }
      .actual {
        display: block;
        height: 100%;
        background-color: #e56300;
        margin-right: auto;
      }
      .overtime {
        background-color: red;
      }
    }
  }
}

/* 筛选内容 */
.searchContent {
  position: absolute;
  bottom: 97px;
  left: 0;
  width: 100%;
  height: 435px;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  background-color: #d4d2d2;
}
.searchContent > li {
  height: 406px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;
}

.searchContent > li.equipment,
.searchContent > li.state {
  background-color: transparent;
}
.searchContent > li.operation-room-id {
  width: 30%;
  padding: 0 20px;
  margin: 0 11px 0 20px;
}
.searchContent > li.operation-type {
  width: 30%;
  padding: 0 20px;
  margin: 0 11px 0 20px;
}
.searchContent > li.category {
  width: 30%;
  padding: 0 20px;
  margin: 0 11px 0 20px;
}
.searchContent > li.equipment {
  width: 407px;
  padding: 0 17px;
}
.searchContent > li.button {
  margin-left: 25px;
  background-color: transparent;
  width: 200px;
}
.searchContent > li.state {
  width: 317px;
  padding: 0 19px;
}

// .clear 這段延後開發
// .searchContent > li.button .clear {
//   width: 160px;
//   height: 188px;
//   background-color: #0081b1;
//   position: relative;
//   cursor: pointer;
// }
// .searchContent > li.button .clear img {
//   width: 36px;
//   height: 37px;
//   position: absolute;
//   top: 60px;
//   left: 66px;
// }
// .searchContent > li.button .clear span {
//   display: inline-block;
//   width: 101px;
//   height: 49px;
//   position: absolute;
//   top: 109px;
//   left: 33px;
//   font-size: 24px;
//   font-weight: 600;
//   color: #fff;
//   text-align: center;
// }
.searchContent > li.button .enter {
  margin-top: 25px;
  width: 160px;
  height: 188px;
  background-color: #0081b1;
  position: relative;
  cursor: pointer;
}
.searchContent > li.button .enter img {
  width: 36px;
  height: 37px;
  position: absolute;
  top: 60px;
  left: 66px;
}
.searchContent > li.button .enter span {
  display: inline-block;
  width: 101px;
  height: 49px;
  position: absolute;
  top: 109px;
  left: 33px;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.searchContent > li p {
  display: block;
  width: 100%;
  height: 65px;
  line-height: 65px;
  color: #00285b;
  font-size: 21px;
  text-align: center;
  font-weight: 600;
}
.searchContent > li > ol {
  width: 100%;
  height: calc(100% - 65px);
  overflow: auto;
}
.searchContent > li > ol::-webkit-scrollbar {
  width: 0.2rem;
}
.searchContent > li > ol::-webkit-scrollbar-thumb {
  border-radius: 0.02rem;
  background-color: #1e91be;
}
.searchContent ol > li {
  /* width: 132px; */
  padding: 0 20px;
  height: 46px;
  line-height: 46px;
  margin-bottom: 9px;
  border: 1px solid #a7a6a6;
  border-radius: 12px;
  box-sizing: border-box;
  color: #151414;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  float: left;
  margin-right: 7px;
}
.searchContent ol > li.selected {
  background-color: #0081b1;
  color: #ffffff;
}
.patient ol > li,
.department ol > li {
  margin-right: 16px;
}
.category ol > li {
  /* width: 80px; */
  margin-right: 7px;
}
.pagination_icon {
  display: flex;
  justify-content: center;
  li {
    width: 31px;
    height: 10px;
    background-color: #bdbebd;
    margin: 0px 10px 10px 10px;
  }
  li.selected {
    background-color: #393939;
  }
}
.timeline {
  font-weight: bold;
  font-size: 1.5rem;
  padding: 0.75rem 0px;
}
</style>
