import axios from 'axios';
import router from '@/router/index.js'

const baseURL = window.g.baseURL;


axios.defaults.baseURL = baseURL;

export const api = {
  getNotice: '/imedtac/drsAnnouncement/listByPage',// 获取公告列表

  getOperationInfo: '/imedtac/IDB/patientFindByParams', // 病患多參數查詢（获取病人列表）

  // 病患細節
  getPatientInfo: '/imedtac/IDB/patientDetail', // 病患細節資料查詢（获取病人細節資訊）
  getAnaesDetail: '/imedtac/IDB/anaesDetail', //跟據scheduleId查詢麻醉資訊

  getRoom: '/imedtac/IDB/roomFindByParams',// 病房多參數查詢（获取術房列表）

  getRoomDetail: '/imedtac/IDB/roomDetail',// 病房細節資料查詢（获取術房細節資訊）

  getOpSort: '/imedtac/IDB/opSort',

  getShiftDetail: '/imedtac/operationShift/getShiftDetail', // 根据类型查询当前排班信息

  getEatShift: '/imedtac/coreShiftDetail/getAndBuildByKey', //機動

  getOperationStatus: '/imedtac/operationInfo/countStatusByDate', // 根据日期统计手术状态

  getOpEquip: '/imedtac/IDB/opEquip',

  getOpCount: '/imedtac/IDB/opCount',
}

// import axios from 'axios'

// // const baseURL = 'http://172.18.8.145';
// const baseURL = 'http://192.168.0.168:80';

// axios.defaults.baseURL = baseURL;

// export const api = {
//     getOperationInfo: '/imedtac/operationInfo/findByExampleWithShift', // 手术信息表

//     getOperationInfoScreen: '/imedtac/operationInfo/findByParams', // 筛选手术信息表

//     getOperationStatus: '/imedtac/operationInfo/countStatusByDate', // 根据日期统计手术状态



//     // getNotice: 'http://192.168.0.147:8080/imwardServer/operating/broadcast', // 获取公告列表
//     getNotice: '/imedtac/drsAnnouncement/listByPage', // 获取公告列表

//     getShiftDetail: '/imedtac/operationShift/getShiftDetail', // 根据类型查询当前排班信息
//     getEatShift: '/imedtac/coreShiftDetail/getAndBuildByKey', // 获取接吃饭
// }