const tw = {
  header: {
    //
    totalNumberOfOperations: "Total number of cuts",
    anesthesia: "general anesthesia",
    localAnesthesia: "Local anesthesia",
    operatingRoom: "operating room",
    OpCountApiError: "獲取手術室信息列表失敗，請檢查網絡."
  },
  detailFooter: {
    anaesNote: "麻醉註記",
    medicalDevice: "醫材",
    medicalMaterials: "衛材",
    schedule: "手術排程"
  },
  footer: {
    dayShift: "白班",
    night: "小夜",
    bigNight: "大夜",
    maneuverable: "機動",
    filter: "條件檢索",
    alreadyFirstPage: "已經是第一頁啦！",
    alreadyLastPage: "已經是最後一頁啦！"
  },
  duty: {
    flow: "mobile nurse",
    brushHands: "Hand brushing nurse",
    specializedSurgery: "手術專責",
    dayShiftApiError: "獲取白班信息失敗，網絡錯誤."
  },
  notice: {
    auditNoPassed: "審核不通過",
    important: "重要",
    noticeApiError: "獲取手術室信息列表失敗，請檢查網."
  },
  room: {
    departmentName: "Department",
    operationType: "Type of Knife",
    operationRoomID: "Operating room number",
    scrubNurse: "Hand brushing nurse",
    flow: "mobile nurse",
    determine: "確 認",
    surgeon: "主刀",
    surgical: "technique",
    anaesDoctor: "anesthesiologist",
    regularKnife: "常規刀",
    emergencyKnife: "emergency knife",
    sharpKnife: "Secondary emergency",
    gastroenterology: "胃腸科",
    generalSurgery: "general surgey",
    neuroSurgery: "neurosurgery",
    urology: "Urology",
    orthopedics: "Department of Orthopedics",
    plasticSurgery: "plastic surgery",
    thoracicSurgery: "Thoracic Surgery",
    pediatricSurgery: "Pediatric Surgery",
    cardiovascularSurgery: "Cardiovascular Surgery",
    obstetricsAndGynecology: "Obstetrics and Gynecology",
    dentistry: "Dentistry",
    oralAndMaxillofacialSurgery: "Oral and maxillofacial surgery",
    ophthalmology: "ophthalmology",
    otolaryngology: "Otolaryngology",
    anesthesiaDepartment: "Anesthesia Department",
    loading: "資料載入中",
    roomFindByParamAPIError: "篩選手術室信息列表失敗，請檢查網絡.",
    getRoomFindByParamAPIError: "獲取手術室信息列表失敗，請檢查網絡.",
    toBeArranged: "待安排",
    waitingForPatients: "等候病患",
    EnterOperatingRoom: "進入術房",
    AnesthesiaPrelude: "麻醉前導",
    Scored: "已劃刀",
    BeforeClosingWound: "關閉傷口前",
    AfterClosingWound: "關閉傷口後",
    LeaveOperatingRoom: "離開術房",
    hasNotStarted: "未開始",
    waitingRoom: "等候室",
    enterOR: "入OR",
    signIn: "Sign in",
    timeOut: "Time out",
    signOut: "Sign out",
    cancel: "取消",
    finish: "完成",
    regularKnifeRow: "Surgical knife row (conventional)",
    generalEmergencyKnife: "一般急刀(急診刀)",
    insertKnife: "unscheduled surgery",
    superEmergencySurgery: "super emergency surgery",
    urgentKnife: "urgent  knife",
    beautyKnife: "美容刀",
    delete: "Delete"
  },
  patient: {
    operationName: "手術",
    departmentName: "Department",
    operationType: "Type of Knife",
    operationRoomID: "Operating room number",
    doctor: "Chief surgeon",
    primaryNurse: "專責",
    operationStart: "手術預計開始時間",
    patientStatus: "患者狀態",
    determine: "確 認",
    regularKnife: "常規刀",
    emergencyKnife: "emergency knife",
    sharpKnife: "Secondary emergency",
    gastroenterology: "胃腸科",
    generalSurgery: "general surgey",
    neuroSurgery: "neurosurgery",
    urology: "Urology",
    orthopedics: "Department of Orthopedics",
    plasticSurgery: "plastic surgery",
    thoracicSurgery: "Thoracic Surgery",
    pediatricSurgery: "Pediatric Surgery",
    cardiovascularSurgery: "Cardiovascular Surgery",
    obstetricsAndGynecology: "Obstetrics and Gynecology",
    dentistry: "Dentistry",
    oralAndMaxillofacialSurgery: "Oral and maxillofacial surgery",
    ophthalmology: "ophthalmology",
    otolaryngology: "Otolaryngology",
    anesthesiaDepartment: "Anesthesia Department",
    operationInfoApiError: "篩選手術室信息列表失敗，請檢查網絡.",
    liquid: "液",
    touch: "接觸",
    call: "呼",
    foam: "沫",
    infect: "傳染",
    other: "其他",
    ownExpense: "自費",
    VIP: "VIP",
    drugSensitivity: "藥敏",
    regularKnifeRow: "Surgical knife row (conventional)",
    generalEmergencyKnife: "一般急刀(急診刀)",
    insertKnife: "unscheduled surgery",
    superEmergencySurgery: "super emergency surgery",
    urgentKnife: "urgent  knife",
    beautyKnife: "美容刀",
    delete: "Delete"
  },
  detail: {
    birthdate: "出生日期",
    doctor: "Chief surgeon",
    operationName: "手術名稱",
    specialNote: "照護註記",
    itemsOwnExpense: "自費品項",
    thinking: "考慮中",
    medicalHistory: "病史",
    healthInsuranceAnesthesiaDepth: "健保麻醉深度",
    difficultIntubation: "困難插管",
    identity: "身份別",
    surgicalInstruments: "手術器械",
    quantity: "Quantity",
    no: "第",
    page: "頁",
    total: "共",
    patientApiError: "獲取病患資訊失敗，請檢查網絡.",
    liquid: "液",
    touch: "接觸",
    call: "呼",
    foam: "沫",
    infect: "傳染",
    other: "其他",
    ownExpense: "自費",
    VIP: "VIP",
    drugSensitivity: "藥敏",
    swordSequence: "刀序",
    patient: "病人",
    department: "Department",
    technique: "technique",
    chiefSurgeon: "Chief surgeon",
    OpSortError: "獲取刀序茲資訊失敗，請檢查網絡.",
    toBeArranged: "待安排",
    waitingForPatients: "等候病患",
    EnterOperatingRoom: "進入術房",
    AnesthesiaPrelude: "麻醉前導",
    Scored: "已劃刀",
    BeforeClosingWound: "關閉傷口前",
    AfterClosingWound: "關閉傷口後",
    LeaveOperatingRoom: "離開術房",
    hasNotStarted: "未開始",
    waitingRoom: "等候室",
    enterOR: "入OR",
    signIn: "Sign in",
    timeOut: "Time out",
    signOut: "Sign out",
    cancel: "取消",
    finish: "完成"
  }
};

export default tw;
