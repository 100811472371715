<template>
  <div id="patientinfo">
    <div class="side">
      <div class="room">
        <div
          class="state"
          :class="{
            TSI: detail.operationStatus == '01', // Sign in
            TTO: detail.operationStatus == '02', // Time out
            TCI: detail.operationStatus == '03', // Check in
            TSO: detail.operationStatus == '04', // Check out
            statusW: detail.operationStatus == 'W', // 未開始
            statusP:
              detail.operationStatus == 'WR' || // 等候室
              detail.operationStatus == 'IR' || // 入OR
              detail.operationStatus == 'SI' || // Sign in
              detail.operationStatus == 'TO' || // Time out
              detail.operationStatus == 'SO', // Sign out
            statusC: detail.operationStatus == 'C', // 取消
            statusY: detail.operationStatus == 'Y' // 完成
          }"
        >
          {{ detail.operationStatusName }}
        </div>
        <div
          class="tri"
          :class="{
            triTSI: detail.operationStatus == '01', // Sign in
            triTTO: detail.operationStatus == '02', // Time out
            triTCI: detail.operationStatus == '03', // Check in
            triTSO: detail.operationStatus == '04', // Check out
            triStatusW: detail.operationStatus == 'W', // 未開始
            triStatusP:
              detail.operationStatus == 'WR' || // 等候室
              detail.operationStatus == 'IR' || // 入OR
              detail.operationStatus == 'SI' || // Sign in
              detail.operationStatus == 'TO' || // Time out
              detail.operationStatus == 'SO', // Sign out
            triStatusC: detail.operationStatus == 'C', // 取消
            triStatusY: detail.operationStatus == 'Y' // 完成
          }"
        ></div>
        <div class="room-num">{{ detail.operationRoom }}</div>
      </div>
      <div class="patient">
        <div
          class="gender"
          :class="{
            F: detail.gender == '0', // 女
            M: detail.gender == '1' // 男
          }"
        >
          <div
            class="gender_icon"
            :class="{
              fIcon: detail.gender == '0', // 女
              mIcon: detail.gender == '1' // 男
            }"
          ></div>
        </div>
        <div class="patient-info">
          <div class="patient-num">{{ detail.inpatientNum }}</div>
          <div class="name">{{ detail.patientName }}</div>
          <div class="brithday">
            {{ $t("detail.birthdate") }}：{{ detail.birthdate }}
          </div>
        </div>
      </div>
      <div class="doctor">
        <div class="doctor-info">
          <div class="doctorbox">
            <span class="title">{{ $t("detail.doctor") }}：</span>
            <span class="doctor_name">{{ detail.doctorName }}</span>
          </div>
          <p class="title">{{ $t("detail.operationName") }}：</p>
          <p class="text">{{ detail.operationName }}</p>
        </div>
      </div>
      <div class="special">
        <p>{{ $t("detail.specialNote") }}</p>
        <div class="op">
          <div
            class="box"
            v-for="item in filteredPrecaution(detail.precaution)"
            :key="item"
            :class="{
              one: item == 1,
              two: item == 2,
              three: item == 3,
              four: item == 4,
              five: item == 5,
              six: item == 6,
              seven: item == 7,
              eight: item == 8,
              nine: item == 9
            }"
          >
            {{ item | precautionMsg }}
          </div>
        </div>
        <!-- <div class="box" v-for="(item, index) in detail.imgs" key="index"> -->
        <!-- <img v-for="(item, index) in detail.imgs" key="index" :src="item.src"/> -->
        <!--         <img v-if="detail.note.indexOf('001') > -1" src="../assets/patient/op_icu@2x.png" alt="">
        <img v-if="detail.note.indexOf('002') > -1" src="../assets/patient/op_777@2x.png" alt=""> -->
        <!-- </div> -->
        <!-- <p v-if="item.imgs.length > 3">...</p> -->
      </div>
    </div>
    <div class="content">
      <div class="anesthesia container" v-if="patientIndex == 1">
        <div class="anesthesia">
          <div class="own">
            <p>{{ $t("detail.itemsOwnExpense") }}</p>
            <span>{{ anaesContent.itemsOptional }}</span>
          </div>
          <div class="consider">
            <p>{{ $t("detail.thinking") }}</p>
            <span>{{ anaesContent.itemsConsider }}</span>
          </div>
          <div class="history">
            <p>{{ $t("detail.medicalHistory") }}</p>
            <p>{{ anaesContent.historyMedical }}</p>
            <!--             <ol v-for="members in 2">父親
              <li v-for="disease in 2">糖尿病</li>
            </ol> -->
          </div>
          <div class="fourth">
            <div class="deep">
              <p>{{ $t("detail.healthInsuranceAnesthesiaDepth") }}</p>
              <span>{{ anaesContent.depthAnes }}</span>
            </div>
            <div class="difficulty">
              <p>{{ $t("detail.difficultIntubation") }}</p>
              <span>{{ anaesContent.diffIntubation }}</span>
            </div>
          </div>
          <div class="identity">
            <p>{{ $t("detail.identity") }}</p>
            <span>{{ anaesContent.typeIdentity }}</span>
          </div>
        </div>
      </div>
      <div class="material container" v-if="patientIndex == 2">
        <!-- table寫法 -->
        <div class="material">
          <!-- <table class="recision" border="1" style="border: 1px #3F3E3E dashed;">
            <tr style="border: 1px #3F3E3E dashed;">
              <th>精密儀器</th>
              <th>數量</th>
            </tr>
            <tr v-for="item in 0" style="border: 1px #3F3E3E dashed;">
              <td>laparoscopic surgery</td>
              <td>1</td>
            </tr>
          </table> -->
          <table class="surgery" border="1" style="border: 1px #3F3E3E dashed;">
            <tr style="border: 1px #3F3E3E dashed;">
              <th>{{ $t("detail.surgicalInstruments") }}</th>
              <th>{{ $t("detail.quantity") }}</th>
            </tr>
            <tr
              v-if="item.is_lack === 'N'"
              v-for="(item, i) in eqList"
              :key="i"
              style="border: 1px #3F3E3E dashed;"
            >
              <td>{{ item.name_equip }}</td>
              <td>{{ item.num_equip }}</td>
            </tr>
          </table>

          <div class="pagination" v-if="patientIndex !== 2">
            <span
              >{{ $t("detail.no") }}{{ pageOption.pageSize
              }}{{ $t("detail.page") }}</span
            >
            <span>
              /{{ $t("detail.total") }}{{ pageOption.allPages
              }}{{ $t("detail.page") }}</span
            >
          </div>
        </div>
      </div>
      <DetailFooter
        :patientTabs="patientTabs"
        :pageOption="pageOption"
        :patientIndex="patientIndex"
        @childShifts="switchShift"
        :linkToPatient="linkToPatient"
        :linkToRoom="linkToRoom"
      >
      </DetailFooter>
    </div>
  </div>
</template>

<script>
import DetailFooter from "@/components/detail/DetailFooter.vue";
import i18n from "@/i18n/i18n";

// @ is an alias to /src
import { api } from "@/api/api.js";
export default {
  name: "PatientDetail",
  components: {
    DetailFooter
  },
  data() {
    return {
      patientTabs: true,
      pageOptions: true,
      // 當前頁籤
      patientIndex: 1,
      pageOption: {
        // 翻页
        allPages: 1, // 总页数
        pageSize: 1 // 当前页码
      },
      linkToPatient: true, //點擊Ｘ到病人頁
      linkToRoom: false, //點擊Ｘ到手術室頁
      detail: {
        id: 0,
        patientRoom: "",
        patientName: "",
        operationStatus: "",
        operationStatusName: "",
        doctorName: "",
        patientNum: "",
        inpatientNum: "",
        birthdate: "",
        gender: "",
        operationName: ""
      },
      anaesContent: {},
      eqList: []
    };
  },
  mounted() {},
  methods: {
    switchShift(i) {
      // 列表切换
      this.patientIndex = i;
      this.pageOption.allPages = 1;
      this.pageOption.pageSize = 1;
      // if (i == 1) {
      //   this.initShiftDetail("手術", this.getDateStr(0))
      // } else if (i == 2) {
      //   this.initShiftDetail("其他", this.getDateStr(0))
      // } else if (i == 3) {
      //   this.initShiftDetail("小夜班", null)
      // } else if (i == 4) {
      //   this.initShiftDetail("大夜班", null)
      // } else {
      //   this.initEatShift()
      // }
    },
    filteredPrecaution(precaution) {
      if (precaution) {
        return precaution.split(",");
      } else {
        return [];
      }
    },
    getDateStr(AddDayCount) {
      // 昨日、今日、明日
      var dd = new Date();
      dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
      var y = dd.getFullYear();
      var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1; //获取当前月份的日期
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      return y + "-" + m + "-" + d;
    },
    initPatientInfo() {
      var data = {
        // date: this.getDateStr(0),
        scheduleId: this.$route.params.patientId
      };
      this.$axios.post(api.getPatientInfo, data).then(
        res => {
          if (res.data.success) {
            // if(res.data.message == '查無手術資訊') {
            //   alert("查無資訊.")
            //   this.$router.push('/patient')
            // }
            // this.detail = res.data.result
            // res.data = require('../testdata/getPatientInfo.json')
            // console.dir(res.data)
            if (!res.data.result.operationInfo) {
              this.detail.operationStatus = "";
              this.detail.operationStatusName = "";
              this.detail.operationRoom = "";
              this.detail.doctorName = "";
              this.detail.operationName = "";
            }
            if (res.data.result.operationInfo) {
              this.detail.operationStatus =
                res.data.result.operationInfo.operationStatus;
              this.detail.operationStatusName =
                res.data.result.operationInfo.operationStatusName;
              this.detail.operationRoom =
                res.data.result.operationInfo.operationRoom;
              this.detail.doctorName = res.data.result.operationInfo.doctorName;
              this.detail.operationName =
                res.data.result.operationInfo.operationName;
            }
            if (!res.data.result.patientinfo) {
              this.detail.gender = "";
              this.detail.inpatientNum = "";
              this.detail.patientName = "";
              this.detail.birthdate = "";
              this.detail.precaution = "";
            }
            if (res.data.result.patientinfo) {
              this.detail.gender = res.data.result.patientinfo.gender;
              this.detail.inpatientNum =
                res.data.result.patientinfo.inpatientNum;
              this.detail.patientName = res.data.result.patientinfo.patientName;
              this.detail.birthdate = res.data.result.patientinfo.birthdate;
              this.detail.precaution = res.data.result.patientinfo.precaution;
            }
            // console.dir(this.detail)
          } else {
            alert(i18n.t("detail.patientApiError"));
          }
        },
        err => {
          alert(i18n.t("detail.patientApiError"));
        }
      );
    },
    initAnaesDetail() {
      var id = {
        scheduleId: this.$route.params.patientId
      };
      this.$axios.post(api.getAnaesDetail, id).then(
        res => {
          if (res.data.success) {
            this.anaesContent = res.data.result;
            // console.log(this.anaesContent)
          } else {
            alert(i18n.t("detail.patientApiError"));
          }
        },
        err => {
          alert(i18n.t("detail.patientApiError"));
        }
      );
      // this.anaesContent = require('../testdata/getAnaesDetail.json').result
      // console.dir(this.anaesContent)
    },
    initOpEquip() {
      var id = {
        scheduleId: this.$route.params.patientId
      };
      this.$axios.post(api.getOpEquip, id).then(
        res => {
          if (res.data.success) {
            this.eqList = res.data.result;
            // console.log(this.anaesContent)
          } else {
            alert(i18n.t("detail.patientApiError"));
          }
        },
        err => {
          alert(i18n.t("detail.patientApiError"));
        }
      );
      // this.eqList = require('../testdata/getOpEquip.json').result
      // console.dir(this.eqList)
    }
  },
  conputed: {},
  filters: {
    precautionMsg(precaution) {
      if (precaution == 1) {
        return i18n.t("detail.liquid");
      }
      if (precaution == 2) {
        return i18n.t("detail.touch");
      }
      if (precaution == 3) {
        return i18n.t("detail.call");
      }
      if (precaution == 4) {
        return i18n.t("detail.foam");
      }
      if (precaution == 5) {
        return i18n.t("detail.infect");
      }
      if (precaution == 6) {
        return i18n.t("detail.other");
      }
      if (precaution == 7) {
        return i18n.t("detail.ownExpense");
      }
      if (precaution == 8) {
        return i18n.t("detail.VIP");
      }
      if (precaution == 9) {
        return i18n.t("detail.drugSensitivity");
      }
    }
  },
  created() {
    this.initPatientInfo();
    this.initAnaesDetail();
    this.initOpEquip();
    // console.log(this.$route.params.scheduleId);
  }
};
</script>
<style scoped lang="scss">
@mixin state {
  width: 10.2vh;
  height: 100%;
}
@mixin triangle {
  width: 0px;
  height: 0px;
  // 垂直置中and內縮
  margin: auto 0 auto -5px;
  border-style: solid;
  border-width: 20px 0px 20px 30px;
}
#patientinfo {
  width: 100%;
  height: 100vh;
  display: flex;
  .side {
    height: 100%;
    width: 36.2%;
  }
  .content {
    display: flex;
    flex-direction: column;

    // 表格與footer填滿側邊欄剩下的
    flex-grow: 1;
    .container {
      display: flex;
      flex-direction: column;

      // 表格填滿footer剩下的
      flex-grow: 1;
    }
  }
}
// 房間（第一行）
.side {
  border-right: 1px solid #3f3e3e;
  box-sizing: border-box;
  .room {
    width: 100%;
    height: 10.2%;
    display: flex;
    border-bottom: 1px solid #3f3e3e;
    box-sizing: border-box;
    .state {
      opacity: 1;
      font-size: 2.25rem;
      font-family: Helvetica-Bold;
      text-align: center;
      color: #ffffff;
      line-height: 2.5rem;
      display: flex;
      align-items: center;
      background-color: #2e9ad3;
      @media (max-width: 1440px) {
        font-size: 2.25rem;
      }
    }
    .tri {
      @include triangle;
      border-color: transparent transparent transparent #2e9ad3;
    }
    .TTO {
      @include state;
      background-color: #d69727;
    }
    .triTTO {
      @include triangle;
      border-color: transparent transparent transparent #d69727;
    }
    .TSI {
      @include state;
      background-color: #39bccd;
    }
    .triTSI {
      @include triangle;
      border-color: transparent transparent transparent #39bccd;
    }
    .TSO {
      @include state;
      background-color: #2e9ad3;
    }
    .triTSO {
      @include triangle;
      border-color: transparent transparent transparent #2e9ad3;
    }
    .statusW {
      @include state;
      background-color: #667ab1;
    }
    .triStatusW {
      @include triangle;
      border-color: transparent transparent transparent #667ab1;
    }
    .statusP {
      @include state;
      background-color: #7ec3d6;
    }
    .triStatusP {
      @include triangle;
      border-color: transparent transparent transparent #7ec3d6;
    }
    .statusC {
      @include state;
      background-color: #d89876;
    }
    .triStatusC {
      @include triangle;
      border-color: transparent transparent transparent #d89876;
    }
    .statusY {
      @include state;
      background-color: #1b5b6c;
    }
    .triStatusY {
      @include triangle;
      border-color: transparent transparent transparent #1b5b6c;
    }
  }
  .room-num {
    // 置中＆左邊間距
    margin: auto 0 auto 3.1%;
    font-size: 4.5rem;
    font-family: Segoe UI, Segoe UI-Bold;
    font-weight: 700;
    text-align: left;
    color: #206a88;
    line-height: 5.4rem;
  }
}
// 性別那行（第二行）
.side {
  .patient {
    display: flex;
    width: 100%;
    height: 23.5%;
    border-bottom: 1px solid #3f3e3e;
    box-sizing: border-box;
    .M {
      width: 10.2vh;
      height: 100%;
      background-color: #5270ab;
      .mIcon {
        background: url("../assets/patient/man@2x.png") no-repeat;
        background-size: 25%;
        height: 100%;
        background-position: center 20%;
      }
    }
    .F {
      width: 10.2vh;
      height: 100%;
      background-color: #d8bbcd;
      .fIcon {
        background: url("../assets/patient/female@2x.png") no-repeat;
        background-size: 25%;
        height: 100%;
        background-position: center 20%;
      }
    }
    .patient-info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 5.5%;
      .patient-num {
        font-size: 1.5rem;
        font-family: STHeitiTC-Medium;
        color: #303030;
        line-height: 1.81rem;
      }
      .name {
        font-size: 4.5rem;
        font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #206a88;
        line-height: 5.375rem;
      }
      .brithday {
        font-size: 2.25rem;
        font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
        font-weight: 700;
        color: #206a88;
        line-height: 2.68rem;
      }
    }
  }
}
.side {
  .doctor {
    width: 100%;
    height: 24%;
    border-bottom: 1px solid #3f3e3e;
    box-sizing: border-box;
    font-size: 2.25rem;
    font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
    font-weight: 700;
    line-height: 2.68rem;
    @media (max-width: 1440px) {
      font-size: 2.25rem;
    }
    .doctor-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 5.5%;
      .title {
        color: #206a88;
      }
      .text {
        color: #303030;
      }
    }
  }
}
.side {
  .special {
    width: 100%;
    margin: 16px 0 0 5.5%;
    p {
      font-size: 2.25rem;
      font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
      font-weight: 700;
      line-height: 2.68rem;
      color: #206a88;
    }
    .op {
      display: flex;
      flex-wrap: wrap;
      .box {
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4rem;
        width: 4rem;
        background-color: #9d9d9d;
        color: white;
        border-radius: 50%;
        font-size: 1.625rem;
      }
      .one {
        background-color: #bf3230;
      }
      .two {
        background-color: #e28e38;
      }
      .three {
        background-color: #56a6e0;
      }
      .four {
        background-color: #89ad15;
      }
      .five {
        background-color: #8a9295;
      }
      .six {
        background-color: #1292be;
      }
      .seven {
        background-color: #418396;
      }
      .eight {
        background-color: #5e80c1;
      }
      .nine {
        background-color: #cc9baf;
      }
    }
    // img{
    //  margin: 32px 2.65% 0 0;
    //  display: inline-block;
    //   width: 10.6%;
    // }
  }
}
// 表格標題css
@mixin title {
  font-size: 1.5rem;
  font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
  font-weight: 700;
  color: #505050;
  line-height: 1.8125rem;
  margin-left: 16px;
}
// 表格內文css
@mixin tbody {
  font-size: 1.875rem;
  font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
  font-weight: 700;
  color: #303030;
  line-height: 2.25rem;
  margin-left: 16px;
}
// 麻醉註記
.content {
  .anesthesia.container {
    background: #efefef;
    // padding-top: 46px;
    padding: 3.75% 4.65% 17.45% 3.93%;
    .anesthesia {
      width: 100%;
      height: 100%;
      z-index: 1;
      border: 1px #3f3e3e dashed;
      box-sizing: border-box;
      // 畫線
      > div:not(:last-child) {
        border-bottom: 1px #3f3e3e dashed;
      }
      .fourth {
        display: flex;
        box-sizing: border-box;
        .deep {
          width: 28.8%;
          height: 100%;
          border-right: 1px #3f3e3e dashed;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          align-content: space-around;
        }
        .difficulty {
          height: 100%;
          width: 71.2%;
          display: flex;
          flex-wrap: wrap;
          align-content: space-around;
        }
      }
      > div {
        width: 100%;
        height: calc(100% / 5);
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        align-content: space-around;
        padding-left: 26px;
        p {
          width: 100%;
          height: 32px;
          // margin-top: 18px;
          // margin-bottom: 21px;
          @include title;
        }
        span {
          line-height: 36px;
          margin-right: 30px;
          @include tbody;
        }
      }
      // 病史
      ol {
        display: inline-block;
        height: 40px;
        @include title;
        li {
          display: inline-block;
          height: 40px;
          @include tbody;
          margin-right: 30px;
        }
      }
    }
  }
}

// 衛材
.content {
  .eisai.container {
    background: #efefef;
    // padding-top: 46px;
    padding: 3.75% 4.65% 4.65% 4.65%;
    .eisai {
      width: 100%;
      height: 100%;
      z-index: 1;
      border: 1px #3f3e3e dashed;
      box-sizing: border-box;
      > div {
        height: calc(100% / 14);
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        span:nth-child(2) {
          margin-right: 56px;
          // 表格右邊數量要對正中間，所以給寬度
          width: 48px;
          text-align: center;
        }
      }
      > div:not(:first-child) {
        border-top: 1px #3f3e3e dashed;
      }
      .eisai-th {
        span {
          @include title;
        }
      }
      .eisai-td {
        span {
          @include tbody;
        }
      }
    }
  }
}

// table寫法
.content {
  .material.container {
    background: #efefef;
    padding: 3.75% 4.65% 4.65% 3.9%;
    overflow: auto;
    .material {
      height: 100%;
      width: 100%;
      table {
        width: 100%;
        // vertical-align: middle;
        // height: 100%;
        margin-bottom: 34px;
        tr {
          height: 61px;

          // 左邊欄位
          > :nth-child(1) {
            text-align: left;
            vertical-align: middle;
            padding-left: 26px;
          }
          // 右邊欄位
          > :nth-child(2) {
            text-align: center;
            vertical-align: middle;
          }
        }
        @media only screen and (min-width: 1680px) and (max-width: 1919px) {
          tr {
            height: 50px;
          }
        }
        @media only screen and (min-width: 1440px) and (max-width: 1679px) {
          tr {
            height: 35px;
          }
        }
        // 第一欄（標題）
        th {
          font-size: 1.5rem;
          font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
          font-weight: 700;
          color: #505050;
          line-height: 1.8125rem;
        }
        // 內文英文
        td:nth-child(1) {
          font-size: 1.875rem;
          font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
          font-weight: 700;
          color: #303030;
          line-height: 2.25rem;
        }
        // 內文數量
        td:nth-child(2) {
          font-size: 1.875rem;
          font-family: Segoe UI, Segoe UI-Bold;
          font-weight: 700;
          text-align: center;
          color: #0081b1;
          line-height: 2.25rem;
        }
      }
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  font-size: 2rem;
  height: 30px;
  > :nth-child(1) {
    color: #393939;
  }
  > :nth-child(2) {
    color: #bdbebd;
  }
}
.people {
  height: 20%;
  position: absolute;
  z-index: 0;
  right: 4%;
  bottom: 19%;
}
</style>
