<template>
  <div id="patient">
    <Header></Header>
    <div class="container">
      <ul class="content">
        <router-link
          tag="li"
          class="patientlist"
          v-for="item in patientList"
          :to="{
            name: 'PatientDetail',
            params: { patientId: item.operationInfo.scheduleId }
          }"
          :key="item.operationInfo.id"
        >
          <div
            class="state"
            :class="{
              noStatus: item.operationInfo.operationStatus == null,
              statusW: item.operationInfo.operationStatus == 'W', // 未開始
              statusP:
                item.operationInfo.operationStatus == 'WR' || // 等候室
                item.operationInfo.operationStatus == 'IR' || // 入OR
                item.operationInfo.operationStatus == 'SI' || // Sign in
                item.operationInfo.operationStatus == 'TO' || // Time out
                item.operationInfo.operationStatus == 'SO', // Sign out
              statusC: item.operationInfo.operationStatus == 'C', // 取消
              statusY: item.operationInfo.operationStatus == 'Y', // 完成
              // 急診報到、門診報到、病房報到
              checkIn:
                item.operationInfo.operationStatus == '01' ||
                item.operationInfo.operationStatus == '02' ||
                item.operationInfo.operationStatus == '03',
              // 空房
              unsorted: item.operationInfo.operationStatus == '04',
              // 已排刀
              sorted: item.operationInfo.operationStatus == '05',
              //等候室
              waitingRoom: item.operationInfo.operationStatus == '06',
              //手術中
              operation: item.operationInfo.operationStatus == '07',
              // 進入恢復室
              enterRecovery: item.operationInfo.operationStatus == '08',
              //離開恢復室
              leaveRecovery: item.operationInfo.operationStatus == '09',
              // 檢驗科
              Laboratory: item.operationInfo.operationStatus == '10',
              // 放射科
              radiology: item.operationInfo.operationStatus == '11',
              // 回急診
              emergency: item.operationInfo.operationStatus == '12',
              // 回門診、回病房、回ICU
              back:
                item.operationInfo.operationStatus == '13' ||
                item.operationInfo.operationStatus == '14' ||
                item.operationInfo.operationStatus == '15',
              // 取消（未麻醉）
              callOff: item.operationInfo.operationStatus == '16',
              // 取消（已麻醉）
              callOn: item.operationInfo.operationStatus == '17'
            }"
          >
            {{ item.operationInfo.operationStatusName }}
          </div>
          <div
            class="tri"
            :class="{
              noStatusTri: item.operationInfo.operationStatus == null,
              triStatusW: item.operationInfo.operationStatus == 'W', // 未開始
              triStatusP:
                item.operationInfo.operationStatus == 'WR' || // 等候室
                item.operationInfo.operationStatus == 'IR' || // 入OR
                item.operationInfo.operationStatus == 'SI' || // Sign in
                item.operationInfo.operationStatus == 'TO' || // Time out
                item.operationInfo.operationStatus == 'SO', // Sign out
              triStatusC: item.operationInfo.operationStatus == 'C', // 取消
              triStatusY: item.operationInfo.operationStatus == 'Y', // 完成
              checkInTri:
                item.operationInfo.operationStatus == '01' ||
                item.operationInfo.operationStatus == '02' ||
                item.operationInfo.operationStatus == '03',
              // 空房
              unsortedTri: item.operationInfo.operationStatus == '04',
              // 已排刀
              sortedTri: item.operationInfo.operationStatus == '05',
              //等候室
              waitingRoomTri: item.operationInfo.operationStatus == '06',
              //手術中
              operationTri: item.operationInfo.operationStatus == '07',
              // 進入恢復室
              enterRecoveryTri: item.operationInfo.operationStatus == '08',
              //離開恢復室
              leaveRecoveryTri: item.operationInfo.operationStatus == '09',
              // 檢驗科
              LaboratoryTri: item.operationInfo.operationStatus == '10',
              // 放射科
              radiologyTri: item.operationInfo.operationStatus == '11',
              // 回急診
              emergencyTri: item.operationInfo.operationStatus == '12',
              // 回門診、回病房、回ICU
              backTri:
                item.operationInfo.operationStatus == '13' ||
                item.operationInfo.operationStatus == '14' ||
                item.operationInfo.operationStatus == '15',
              // 取消（未麻醉）
              callOffTri: item.operationInfo.operationStatus == '16',
              // 取消（已麻醉）
              callOnTri: item.operationInfo.operationStatus == '17'
            }"
          ></div>
          <div class="patientroom">
            <p>{{ item.operationInfo.operationRoom | roomMsg }}</p>
          </div>
          <div class="operationType">
            <span>{{
              item.operationInfo.operationType | operationTypeMsg
            }}</span>
          </div>
          <div class="name">
            <span>{{ item.operationInfo.patientName }}</span>
            <span>{{ item.operationInfo.patientNum }}</span>
          </div>
          <div class="operationText">
            <!-- 手術 -->
            <p>{{ $t("patient.operationName") }}</p>
            <div>{{ item.operationInfo.operationName }}</div>
          </div>
          <div class="category">
            <!-- 科別 -->
            <p>{{ $t("patient.departmentName") }}</p>
            <div>{{ item.operationInfo.departmentName }}</div>
          </div>
          <div class="doctor">
            <div class="docTop">
              <img src="../assets/patient/doctor_icon@2x.png" alt="" />
              <!-- 主刀醫生 -->
              <span>{{ $t("patient.doctor") }}</span>
            </div>
            <div>{{ item.operationInfo.doctorName }}</div>
          </div>
          <!-- 專責 -->
          <!-- <div class="nurse"> -->
          <!-- <p>{{ $t("patient.primaryNurse") }}</p>
            <div v-if="item.patientinfo" >{{ item.patientinfo.nurse }}</div>
          </div> -->
          <div class="time">
            <!-- 手術預計開始時間 -->
            <p>{{ $t("patient.operationStart") }}</p>
            <div>{{ item.operationInfo.operationStart | timeMsg }}</div>
          </div>
          <div class="op">
            <div
              class="box"
              v-for="note in filteredPrecaution(item.patientinfo.precaution)"
              :key="note"
              :class="{
                one: note == 1,
                two: note == 2,
                three: note == 3,
                four: note == 4,
                five: note == 5,
                six: note == 6,
                seven: note == 7,
                eight: note == 8,
                nine: note == 9
              }"
            >
              {{ note | precautionMsg }}
            </div>
            <p v-if="precautionLength(item.patientinfo.precaution)">...</p>
          </div>
        </router-link>
      </ul>
    </div>
    <Footer
      :tabIndex="tabIndex"
      :pageOptions="pageOptions"
      :pageOption="pageOption"
      :retrievalState="retrievalState"
      @childSearchContent="searchContentClick"
      ref="child"
    ></Footer>
    <ul class="searchContent" v-if="searchContent">
      <li class="operation-type">
        <p>{{ $t("patient.operationType") }}</p>
        <ol>
          <li
            v-for="(item, i) in operationTypeList"
            :key="i"
            @click="condition('operationType', item.code)"
            :class="{ selected: nowOperationTypeList.includes(item.code) }"
          >
            {{ item.name }}
          </li>
        </ol>
      </li>
      <li class="category">
        <p>{{ $t("patient.departmentName") }}</p>
        <ol>
          <li
            v-for="(item, i) in categoryList"
            :key="i"
            @click="condition('category', item)"
            :class="{ selected: nowCategoryList.includes(item) }"
          >
            {{ item }}
          </li>
        </ol>
      </li>
      <li class="operation-room-id">
        <p>{{ $t("patient.operationRoomID") }}</p>
        <ol>
          <li
            v-for="(item, i) in operationRoomIDList"
            :key="i"
            @click="condition('operationRoomID', item)"
            :class="{ selected: nowOperationRoomIDList.includes(item) }"
          >
            {{ item }}
          </li>
        </ol>
      </li>
      <li class="button">
        <!--         <div class="clear" @click="clearClick()">
          <img src="../assets/footer/clear.png" alt="">
          <span>清除</span>
        </div> -->
        <div class="enter" @click="enterClick()">
          <img src="../assets/footer/enter.png" alt="" />
          <span>{{ $t("patient.determine") }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<!-- <img v-if="item.op.seven" src="../assets/patient/op_777@2x.png" alt=""> -->
<!-- <img v-if="!op.imgUrl" src="../assets/patient/op_icu@2x.png" alt=""> -->
<!--             <img src="../assets/patient/op_777@2x.png" alt="">
            <img src="../assets/patient/op_777@2x.png" alt="">
            <img src="../assets/patient/op_777@2x.png" alt=""> -->
<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { api } from "@/api/api.js";
import moment from "moment";
import i18n from "@/i18n/i18n";

export default {
  name: "Patient",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      tabIndex: 2,
      pageOptions: true,
      loading: false,
      pageOption: {
        // 翻页
        allPages: 2, // 总页数
        pageSize: 1 // 当前页码
      },
      retrievalState: true, // 显示条件检索(footer上的按鈕)
      searchContent: false, // 显示条件检索内容（點擊按鈕會跳出/選單）
      operationTypeList: [
        { code: "1", name: i18n.t("patient.regularKnife") },
        { code: "2", name: i18n.t("patient.emergencyKnife") },
        { code: "3", name: i18n.t("patient.sharpKnife") } // 2, 3, 4, 5
      ],
      nowOperationTypeList: [], // 選中檢所(刀種)列表
      operationRoomIDList: [
        "R01",
        "R02",
        "R03",
        "R04",
        "R05",
        "R06",
        "R07",
        "R08",
        "R09",
        "R10",
        "R11",
        "R12",
        "R13",
        "R14",
        "R15",
        "R16",
        "R17",
        "R18",
        "R19",
        "R20",
        "R21",
        "R22",
        "R23",
        "R24",
        "R25",
        "R26",
        "R27",
        "R28",
        "R29"
      ], // 手術房號
      nowOperationRoomIDList: [], // 選中手術房號列表
      categoryList: [
        i18n.t("patient.gastroenterology"),
        i18n.t("patient.generalSurgery"),
        i18n.t("patient.neuroSurgery"),
        i18n.t("patient.urology"),
        i18n.t("patient.orthopedics"),
        i18n.t("patient.plasticSurgery"),
        i18n.t("patient.thoracicSurgery"),
        i18n.t("patient.pediatricSurgery"),
        i18n.t("patient.cardiovascularSurgery"),
        i18n.t("patient.obstetricsAndGynecology"),
        i18n.t("patient.dentistry"),
        i18n.t("patient.oralAndMaxillofacialSurgery"),
        i18n.t("patient.ophthalmology"),
        i18n.t("patient.otolaryngology"),
        i18n.t("patient.anesthesiaDepartment")
      ], // 检索(科别)列表
      nowCategoryList: [], // 选中检索(科别)列表
      patientList: [],
      whetherDilter: false // 是否進行篩選
    };
  },
  computed: {},
  watch: {
    "pageOption.pageSize"() {
      this.whetherDilter ? this.enterClick() : this.initOperationInfo();
    },
    "pageOption.allPages"() {
      // 改变时，让当前页码等于1
      this.pageOption.pageSize = 1;
    }
  },
  methods: {
    searchContentClick(state) {
      // 接收子元素传过来的数据
      this.searchContent = state;
    },
    async enterClick() {
      this.searchContent = false;
      this.$refs.child.searchContent = false;
      this.filterFlag = true;
      var screenData = {
        date: moment().format("YYYY-MM-DD")
      };
      if (this.nowCategoryList.length > 0) {
        screenData.department_name_list = this.nowCategoryList;
      }
      if (this.nowOperationTypeList.length > 0) {
        // 3 要傳 2, 3, 4, 5
        if (this.nowOperationTypeList.includes("3")) {
          let temp = this.nowOperationTypeList.slice(0);
          // 沒 2 補 2
          if (!this.nowOperationTypeList.includes("2")) {
            temp.push("2");
          }
          temp.push("4", "5");
          screenData.operation_type_list = temp;
        } else {
          screenData.operation_type_list = this.nowOperationTypeList;
        }
      }
      if (this.nowOperationRoomIDList.length > 0) {
        screenData.operation_room_list = this.nowOperationRoomIDList;
      }
      // 可用es6c樣板字面值（template Literal） `${api.getOperationInfoScreen} ?pageNum= ${this.pageOption.pageSize} &pageSize=8, screenData`
      await this.$axios
        .post(
          api.getOperationInfo +
            "?pageNum=" +
            this.pageOption.pageSize +
            "&pageSize=8",
          screenData
        )
        .then(
          res => {
            if (res.data.success) {
              this.patientList = res.data.result.list;
              this.pageOption.allPages = res.data.result.pages;
              this.whetherDilter = true;
            } else {
              alert(i18n.t("patient.operationInfoApiError"));
            }
          },
          err => {
            alert(i18n.t("patient.operationInfoApiError"));
          }
        );
      // // TODO TEST
      // let json = require("../testdata/patientFindByParams.json");
      // this.patientList = json.result.list
    },
    condition(type, data) {
      // 筛选
      let nowListName = "";
      switch (type) {
        case "category":
          nowListName = "nowCategoryList";
          break;
        case "operationType":
          nowListName = "nowOperationTypeList";
          break;
        case "operationRoomID":
          nowListName = "nowOperationRoomIDList";
          break;
      }

      if (this[nowListName] !== "") {
        if (this[nowListName].includes(data)) {
          this[nowListName] = this[nowListName].filter(function(ele) {
            return ele !== data;
          });
        } else {
          this[nowListName].push(data);
        }
      }
    },
    filteredPrecaution(precaution) {
      if (precaution) {
        return precaution.split(",").slice(0, 3);
      } else {
        return [];
      }
    },
    precautionLength(precaution) {
      if (precaution) {
        if (precaution.length > 3) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    clearClick() {
      this.nowPatientStatusLis.length = 0;
    },
    async initOperationInfo() {
      // 获取手术信息表
      const currentDateTime = moment().format("YYYY-MM-DD");
      var data = {
        date: currentDateTime
      };
      await this.$axios
        .post(
          api.getOperationInfo +
            "?pageNum=" +
            this.pageOption.pageSize +
            "&pageSize=8",
          data
        )
        .then(
          res => {
            if (res.data.success) {
              this.patientList = res.data.result.list;
              this.pageOption.allPages = res.data.result.pages;
            } else {
              alert(i18n.t("patient.operationInfoApiError"));
            }
          },
          err => {
            alert(i18n.t("patient.operationInfoApiError"));
          }
        );
      // // TODO TEST
      // let json = require("../testdata/patientFindByParams.json");
      // this.patientList = json.result.list
    }
  },
  filters: {
    timeMsg(date) {
      if (date) {
        return date.substr(11, 5);
      }
    },
    roomMsg(name) {
      if (name) {
        return name;
      }
      if (name == null) {
        return "--";
      }
    },
    precautionMsg(precaution) {
      if (precaution == 1) {
        return i18n.t("patient.liquid");
      }
      if (precaution == 2) {
        return i18n.t("patient.touch");
      }
      if (precaution == 3) {
        return i18n.t("patient.call");
      }
      if (precaution == 4) {
        return i18n.t("patient.foam");
      }
      if (precaution == 5) {
        return i18n.t("patient.infect");
      }
      if (precaution == 6) {
        return i18n.t("patient.other");
      }
      if (precaution == 7) {
        return i18n.t("patient.ownExpense");
      }
      if (precaution == 8) {
        return i18n.t("patient.VIP");
      }
      if (precaution == 9) {
        return i18n.t("patient.drugSensitivity");
      }
    },
    operationTypeMsg(type) {
      switch (type) {
        case "1":
          return i18n.t("patient.regularKnifeRow");
        case "2":
          return i18n.t("patient.generalEmergencyKnife");
        case "3":
          return i18n.t("patient.insertKnife");
        case "4":
          return i18n.t("patient.superEmergencySurgery");
        case "5":
          return i18n.t("patient.urgentKnife");
        case "6":
          return i18n.t("patient.beautyKnife");
        case "X":
          return i18n.t("patient.delete");
        default:
          return "";
      }
    }
  },
  created() {
    this.initOperationInfo();
    this.timer = setInterval(() => {
      if (this.filterFlag) {
        this.enterClick();
      } else {
        this.initOperationInfo();
      }
    }, window.g.updateTime * 1000 * 60);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  }, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
@mixin title {
  height: 47.5%;
  opacity: 1;
  font-size: 1.06rem;
  font-family: MicrosoftJhengHeiBold;
  text-align: left;
  color: #777776;
  line-height: 21px;
}
@mixin contentEN {
  width: 100%;
  height: 33.75%;
  opacity: 1;
  font-size: 1.25rem;
  font-family: SegoeUI-Bold;
  text-align: left;
  color: #206a88;
  // line-height: 24px;
  white-space: nowrap;
  //自動隱藏文字
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin contentTC {
  width: 100%;
  height: 33.75%;
  opacity: 1;
  font-size: 1.25rem;
  font-family: MicrosoftJhengHeiBold;
  text-align: left;
  color: #206a88;
  line-height: 24px;
}

// 狀態EX 未排刀
@mixin state {
  display: flex;
  align-items: center;
  height: 100%;
  width: 11.53%;
  border-radius: 25px 0 0 25px;
}
// 三角形
@mixin triangle {
  width: 0px;
  height: 0px;
  // 垂直置中and內縮
  margin: auto 0.9% auto -5px;
  border-style: solid;
  border-width: 20px 0px 20px 30px;
}
#patient {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  .container {
    display: flex;
    flex-grow: 1;
    background: #efefef;
    // 減掉header與footer的高度
    height: calc(100% - 9.5% - 97px);
  }
}

.content {
  width: 100%;
  li.patientlist {
    width: 98%;
    height: 9.1%;
    background: #ffffff;
    border-radius: 25px 17px 25px 25px;
    margin: 27px auto 0;
    display: flex;
    align-items: center;

    @media (max-height: 920px) {
      margin: 10px auto 0;
    }
    @media (max-height: 1000px) {
      margin: 17px auto 0;
    }
    .state {
      width: 11.5%;
      height: 100%;
      line-height: 80px;
      font-size: 1.875rem;
      padding-left: 16px;
      box-sizing: border-box;
      color: #ffffff;
      font-weight: 600;
    }
  }
}
// 列表前方狀態圖片 未排刀、已排刀
li.patientlist {
  .tri {
    @include triangle;
    border-color: transparent transparent transparent white;
  }
  // 這幾個alpha測試沒問題就會刪掉（06-08）
  // 06
  .bedWard {
    @include state;
    background-color: #d89876;
  }
  .bedWardTri {
    @include triangle;
    border-color: transparent transparent transparent #d89876;
  }
  // 07
  .sickroom {
    @include state;
    background-color: #e2b6c9;
  }
  .sickroomTri {
    @include triangle;
    border-color: transparent transparent transparent #e2b6c9;
  }
  // 08手術完成
  .complete {
    @include state;
    background-color: #485882;
  }
  .completeTri {
    @include triangle;
    border-color: transparent transparent transparent #485882;
  }
  // 下面是新的
  .noStatus {
    @include state;
    background-color: #9d9d9d;
  }
  .noStatusTri {
    @include triangle;
    border-color: transparent transparent transparent #9d9d9d;
  }
  .statusW {
    @include state;
    background-color: #667ab1;
  }
  .triStatusW {
    @include triangle;
    border-color: transparent transparent transparent #667ab1;
  }
  .statusP {
    @include state;
    background-color: #7ec3d6;
  }
  .triStatusP {
    @include triangle;
    border-color: transparent transparent transparent #7ec3d6;
  }
  .statusC {
    @include state;
    background-color: #d89876;
  }
  .triStatusC {
    @include triangle;
    border-color: transparent transparent transparent #d89876;
  }
  .statusY {
    @include state;
    background-color: #1b5b6c;
  }
  .triStatusY {
    @include triangle;
    border-color: transparent transparent transparent #1b5b6c;
  }
  .checkInTri {
    @include triangle;
    border-color: transparent transparent transparent #87c1d1;
  }
  // 急診報到、門診報到、病房報到
  .checkIn {
    @include state;
    background-color: #87c1d1;
  }
  .checkInTri {
    @include triangle;
    border-color: transparent transparent transparent #87c1d1;
  }
  // 進入恢復室
  .enterRecovery {
    @include state;
    background-color: #7575ac;
  }
  .enterRecoveryTri {
    @include triangle;
    border-color: transparent transparent transparent #7575ac;
  }
  // 離開恢復室
  .leaveRecovery {
    @include state;
    background-color: #764882;
  }
  .leaveRecoveryTri {
    @include triangle;
    border-color: transparent transparent transparent #764882;
  }
  // 檢驗科
  .Laboratory {
    @include state;
    background-color: #1a7189;
  }
  .LaboratoryTri {
    @include triangle;
    border-color: transparent transparent transparent #1a7189;
  }
  // /* 已排刀 */
  .sorted {
    @include state;
    background-color: #d69727;
  }
  .sortedTri {
    @include triangle;
    border-color: transparent transparent transparent #d69727;
  }
  // 未排刀
  .unsorted {
    @include state;
    background-color: #d89876;
  }
  .unsortedTri {
    @include triangle;
    border-color: transparent transparent transparent #d89876;
  }
  // 手術中
  .operation {
    @include state;
    background-color: #89ad15;
  }
  .operationTri {
    @include triangle;
    border-color: transparent transparent transparent #89ad15;
  }
  // 放射科
  .radiology {
    @include state;
    background-color: #0c8472;
  }
  .radiologyTri {
    @include triangle;
    border-color: transparent transparent transparent #0c8472;
  }
  // 取消（未麻醉）
  .callOff {
    @include state;
    background-color: #4880b9;
  }
  .callOffTri {
    @include triangle;
    border-color: transparent transparent transparent #4880b9;
  }
  // 取消（已麻醉）
  .callOn {
    @include state;
    background-color: #4395a3;
  }
  .callOnTri {
    @include triangle;
    border-color: transparent transparent transparent #4395a3;
  }
  // 等候室
  .waitingRoom {
    @include state;
    background-color: #667ab1;
  }
  .waitingRoomTri {
    @include triangle;
    border-color: transparent transparent transparent #667ab1;
  }
  // 回急診
  .emergency {
    @include state;
    background-color: #aa363f;
  }
  .emergencyTri {
    @include triangle;
    border-color: transparent transparent transparent #aa363f;
  }
  // 回門診、回病房、回ICU
  .back {
    @include state;
    background-color: #cc9baf;
  }
  .backTri {
    @include triangle;
    border-color: transparent transparent transparent #cc9baf;
  }
}

li.patientlist {
  .patientroom {
    width: 3.1%;
    line-height: 5rem;
    color: #206a88;
    font-size: 3rem;
    font-weight: 600;
    margin-right: 2.76%;
    // 裝置較小時字體太大會跑版
    @media (max-width: 1440px) {
      font-size: 2rem;
    }
    @media (max-width: 1560px) {
      font-size: 2.5rem;
    }
  }
  .operationType {
    padding: 10px;
    margin: 10px;
    width: 7%;
    height: 50%;
    color: white;
    background-color: #0081b1;
    border-radius: 10px;
    // 置中
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .name {
    width: 13.45%;
    position: relative;
    height: 100%;
    // 分隔線寫在手術欄位
    // border-right: 1px solid #206a88;
    box-sizing: border-box;
    span {
      display: block;
      width: 100%;
      height: 60%;
      color: #206a88;
      font-size: 2.625rem;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // line-height: 3.18rem;
    }
    // 第二個子元素
    > :nth-child(2) {
      height: 40%;
      font-size: 1.25rem;
      color: #777776;
      line-height: 1.5em;
      padding-left: 5px;
    }
  }
  .operationText {
    width: 16.85%;
    height: 75%;
    padding-left: 1%;
    border-left: 1px solid #206a88;
    border-right: 1px solid #206a88;
    box-sizing: border-box;
    p {
      @include title;
    }
    div {
      @include contentEN;
    }
  }
  .category {
    width: 9%;
    height: 75%;
    padding-left: 1%;
    overflow: hidden;
    border-right: 1px solid #206a88;
    box-sizing: border-box;
    p {
      @include title;
    }
    div {
      @include contentEN;
    }
  }
  .doctor {
    // display: flex;
    width: 7.5%;
    height: 75%;
    padding-left: 0.7%;
    border-right: 1px solid #206a88;
    box-sizing: border-box;
    .docTop {
      display: flex;
      // justify-content: space-between;
      height: 46.6%;
      img {
        margin-top: -1%;
        height: 109%;
      }
      span {
        @include title;
      }
    }
    div {
      @include contentTC;
    }
  }
  .nurse {
    width: 5%;
    height: 75%;
    padding-left: 0.6%;
    border-right: 1px solid #206a88;
    box-sizing: border-box;
    p {
      @include title;
    }
    div {
      @include contentTC;
    }
  }
  .time {
    width: 9.3%;
    height: 75%;
    padding-left: 0.5%;
    border-right: 1px solid #206a88;
    box-sizing: border-box;
    p {
      @include title;
    }
    div {
      @include contentEN;
    }
  }
  .op {
    width: 17.8%;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    .box {
      margin-left: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4rem;
      width: 4rem;
      background-color: #9d9d9d;
      color: white;
      border-radius: 50%;
      font-size: 1.625rem;
    }
    .one {
      background-color: #bf3230;
    }
    .two {
      background-color: #e28e38;
    }
    .three {
      background-color: #56a6e0;
    }
    .four {
      background-color: #89ad15;
    }
    .five {
      background-color: #8a9295;
    }
    .six {
      background-color: #1292be;
    }
    .seven {
      background-color: #418396;
    }
    .eight {
      background-color: #5e80c1;
    }
    .nine {
      background-color: #cc9baf;
    }
    p {
      margin-left: 2%;
      margin-top: auto;
      font-size: 2rem;
    }
  }
}

/* 筛选内容 */
.searchContent {
  position: absolute;
  bottom: 97px;
  left: 0;
  width: 100%;
  height: 435px;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  background-color: #d4d2d2;
}
.searchContent > li {
  height: 406px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;
}

.searchContent > li.equipment,
.searchContent > li.state {
  background-color: transparent;
}
.searchContent > li.operation-room-id {
  width: 30%;
  padding: 0 20px;
  margin: 0 11px 0 20px;
}
.searchContent > li.operation-type {
  width: 30%;
  padding: 0 20px;
  margin: 0 11px 0 20px;
}
.searchContent > li.category {
  width: 30%;
  padding: 0 20px;
  margin: 0 11px 0 20px;
}
.searchContent > li.equipment {
  width: 407px;
  padding: 0 17px;
}
.searchContent > li.button {
  margin-left: 25px;
  background-color: transparent;
  width: 200px;
}
.searchContent > li.state {
  width: 317px;
  padding: 0 19px;
}
// .clear 這段延後開發
// .searchContent > li.button .clear {
//   width: 160px;
//   height: 188px;
//   background-color: #0081b1;
//   position: relative;
//   cursor: pointer;
// }
// .searchContent > li.button .clear img {
//   width: 36px;
//   height: 37px;
//   position: absolute;
//   top: 60px;
//   left: 66px;
// }
// .searchContent > li.button .clear span {
//   display: inline-block;
//   width: 101px;
//   height: 49px;
//   position: absolute;
//   top: 109px;
//   left: 33px;
//   font-size: 24px;
//   font-weight: 600;
//   color: #fff;
//   text-align: center;
// }
.searchContent > li.button .enter {
  margin-top: 25px;
  width: 160px;
  height: 188px;
  background-color: #0081b1;
  position: relative;
  cursor: pointer;
}
.searchContent > li.button .enter img {
  width: 36px;
  height: 37px;
  position: absolute;
  top: 60px;
  left: 66px;
}
.searchContent > li.button .enter span {
  display: inline-block;
  width: 101px;
  height: 49px;
  position: absolute;
  top: 109px;
  left: 33px;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.searchContent > li p {
  display: block;
  width: 100%;
  height: 65px;
  line-height: 65px;
  color: #00285b;
  font-size: 21px;
  text-align: center;
  font-weight: 600;
}
.searchContent > li > ol {
  width: 100%;
  height: calc(100% - 65px);
  overflow: auto;
}
.searchContent > li > ol::-webkit-scrollbar {
  width: 0.2rem;
}
.searchContent > li > ol::-webkit-scrollbar-thumb {
  border-radius: 0.02rem;
  background-color: #1e91be;
}
.searchContent ol > li {
  /* width: 132px; */
  padding: 0 20px;
  height: 46px;
  line-height: 46px;
  margin-bottom: 9px;
  border: 1px solid #a7a6a6;
  border-radius: 12px;
  box-sizing: border-box;
  color: #151414;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  float: left;
  margin-right: 7px;
}
.searchContent ol > li.selected {
  background-color: #0081b1;
  color: #ffffff;
}
.patient ol > li,
.department ol > li {
  margin-right: 16px;
}
.category ol > li {
  /* width: 80px; */
  margin-right: 7px;
}
</style>
