<template>
  <div id="detail_footer">
    <div class="detail_tab">
      <ul class="patient_tab" v-if="patientTabs">
        <li :class="{ selected: patientIndex === 1 }" @click="patientClick(1)">
          {{ $t("detailFooter.anaesNote") }}
        </li>
        <li :class="{ selected: patientIndex === 2 }" @click="patientClick(2)">
          {{ $t("detailFooter.medicalMaterials") }}
        </li>
        <!-- <li :class="{ selected : patientIndex ===3 }" @click="patientClick(3)">{{ $t("detailFooter.medicalDevice") }}</li> -->
      </ul>
      <ul class="operation_option" v-if="operationTaps">
        <li
          :class="{ selected: operationIndex === 1 }"
          @click="operationClick(1)"
        >
          {{ $t("detailFooter.schedule") }}
        </li>
      </ul>
      <div class="option">
        <ul class="switch">
          <li
            class="back"
            :class="{ backUn: pageOption.pageSize == 1 }"
            @click="preClick"
          ></li>
          <li
            class="next"
            :class="{
              nextUn:
                pageOption.pageSize == pageOption.allPages ||
                pageOption.allPages == 0
            }"
            @click="nextClick"
          ></li>
        </ul>
        <router-link to="/patient" class="X" v-if="linkToPatient"
          >X</router-link
        >
        <router-link to="/" class="X" v-if="linkToRoom">X</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n/i18n";

export default {
  name: "DetailFooter",
  props: {
    // 病人細節頁
    patientTabs: {
      type: Boolean,
      default: false
    },
    patientIndex: {
      // 班次索引
      type: Number,
      default: 1
    },
    operationIndex: {
      // 班次索引
      type: Number,
      default: 1
    },
    // 手術室細節頁
    operationTaps: {
      type: Boolean,
      default: false
    },
    pageOption: {
      // 翻页
      type: Object,
      default: null
    },
    pageOptions: {
      // 条件检索显隐
      type: Boolean,
      default: false
    },
    linkToPatient: {
      type: Boolean,
      default: false
    },
    linkToRoom: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 父子组件之间的通信
    };
  },
  methods: {
    patientClick(index) {
      // 班次切换
      this.$emit("childShifts", index);
    },
    preClick() {
      if (this.pageOption.pageSize > 1) {
        this.pageOption.pageSize--;
        return;
      }
      if ((this.pageOption.pageSize = 1)) {
        alert(i18n.t("footer.alreadyFirstPage"));
        return;
      }
      if (this.pageOption.pageSize < 1) {
        alert(i18n.t("footer.alreadyFirstPage"));
        return;
      }
    },
    nextClick() {
      if (this.pageOption.pageSize < this.pageOption.allPages) {
        this.pageOption.pageSize++;
        return;
      }
      if ((this.pageOption.pageSize = this.pageOption.allPages)) {
        alert(i18n.t("footer.alreadyLastPage"));
        return;
      }
      if (this.pageOption.pageSize > this.pageOption.allPages) {
        alert(i18n.t("footer.alreadyLastPage"));
        return;
      }
    }
  },
  created() {}
};
</script>
<style scoped lang="scss">
@mixin optionText {
  width: 191px;
  // height: 97px;
  line-height: 62px;
  text-align: center;
  font-size: 36px;
  color: #0081b1;
  cursor: pointer;
  font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin optionSelected {
  background-color: #0081b1;
  color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(64, 64, 64, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}
#detail_footer {
  .detail_tab {
    height: 97px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // 排班
    .patient_tab {
      height: 100%;
      display: flex;
      li {
        @include optionText;
      }
      li.selected {
        @include optionSelected;
      }
    }
    .operation_option {
      height: 100%;
      display: flex;

      li {
        @include optionText;
      }
      .selected {
        @include optionSelected;
      }
    }
  }
}
.option {
  display: flex;
  .switch {
    width: 137px;
    height: 100%;
    margin: 0 25px 0 20;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      width: 62px;
      height: 62px;
      cursor: pointer;
    }
    li.back {
      background: url(../../assets/footer/switch/back@2x.png) no-repeat center;
      background-size: 100% 100%;
    }
    li.backUn {
      background: url(../../assets/footer/switch/back_un@2x.png) no-repeat
        center;
      background-size: 100% 100%;
    }
    li.next {
      background: url(../../assets/footer/switch/next@2x.png) no-repeat center;
      background-size: 100% 100%;
    }
    li.nextUn {
      background: url(../../assets/footer/switch/next_un@2x.png) no-repeat
        center;
      background-size: 100% 100%;
    }
  }
  .X {
    @include optionText;
    font-size: 56px;
    text-decoration: none;
  }
  // 去除router-link
  .router-link-active {
    text-decoration: none;
  }
}
</style>
