import Vue from 'vue'
import VueRouter from 'vue-router'
import Overall from '../views/Overall.vue'
import Patient from '../views/Patient.vue'
import PatientDetail from '../views/PatientDetail.vue'
import Notice from '../views/Notice.vue'
import Room from '../views/Room.vue'
import RoomDetail from '../views/RoomDetail.vue'
import Duty from '../views/Duty.vue'
import Maintain from '../views/Maintain.vue'
// import Bottom from '../components/Bottom.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Room',
    component: Room
  },
  {
    path: '/room/:roomId',
    name: 'RoomDetail',
    component: RoomDetail
  },
  {
    path: '/patient/',
    name: 'Patient',
    component: Patient
  },
  {
    path: '/patient/:patientId',
    name: 'PatientDetail',
    component: PatientDetail
  },
  {
    path: '/overall',
    name: 'Overall',
    component: Overall
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Notice
  },
  {
    path: '/duty',
    name: 'Duty',
    component: Duty
  },
  {
    path: '/maintain',
    name: 'Maintain',
    component: Maintain
  }
]

const router = new VueRouter({
  // base: '/idb/',
  // mode: 'history',
  routes
})

export default router
