<template>
  <div id="header">
    <div class="container">
      <div class="name">{{ name }}</div>
      <div class="time_container">
        <div class="time">{{ time }}</div>
        <div class="line"></div>
        <div class="date">{{ date }}</div>
      </div>
      <div class="info_container">
        <div class="all">
          <div>
            <p>{{ $t("header.totalNumberOfOperations") }}</p>
            <img src="../assets/header/allnumber_icon@2x.png" alt="" />
          </div>
          <span>{{ all }}</span>
        </div>

        <div class="note">
          <div>
            <p>{{ $t("header.anesthesia") }}</p>
            <img src="../assets/header/GA@2x.png" alt="" />
          </div>
          <span>{{ note }}</span>
        </div>

        <div class="local">
          <div>
            <p>{{ $t("header.localAnesthesia") }}</p>
            <img src="../assets/header/SA@2x.png" alt="" />
          </div>
          <span>{{ local }}</span>
        </div>
      </div>
      <div class="logo">
        <!-- <img src="../assets/header/chi_mei_medical_center_blue@2x.png" alt="" /> -->
        <!-- 展覽用圖片 -->
        <img src="../assets/header/ICC_logo_login@2x.png" alt="">  
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n/i18n";
import moment from "moment";
import { api } from "@/api/api.js";
export default {
  name: "Header",
  data() {
    return {
      name: i18n.t("header.operatingRoom"),
      time: "",
      date: "",
      all: 0,
      note: 0,
      local: 0
    };
  },
  methods: {
    // 時間
    updateTime() {
      // setInterval(() => this.time = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) , 1000);
      setInterval(() => (this.time = moment().format("h:mm A")), 1000);
    },
    // 日期
    updateDate() {
      // setInterval(() => this.date = new Date().toLocaleDateString() , 1000);
      setInterval(() => (this.date = moment().format("YYYY/MM/DD")), 1000);
    },
    initHeaderInfo() {
      let currentDateTime = moment().format("YYYY-MM-DD");
      let data = {
        date: currentDateTime
      };
      this.$axios.post(api.getOpCount, data).then(
        res => {
          if (res.data.success) {
            this.all = res.data.result.num_outpatient;
            this.note = res.data.result.num_anaes_full;
            this.local = res.data.result.num_anaes_part;
          } else {
            console.log(i18n.t("header.OpCountApiError"));
            // alert("獲取手術室信息列表失敗，請檢查網絡.")
          }
        },
        err => {
          console.log(i18n.t("header.OpCountApiError"));
          // alert("獲取手術室信息列表失敗，請檢查網絡.")
        }
      );
    }
  },
  created() {
    this.updateTime();
    this.updateDate();
    this.initHeaderInfo();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@mixin timeStyle {
  padding-left: 0.4rem;
  font-size: 2.25rem;
  line-height: 2.68rem;
  letter-spacing: 0.1rem;
  width: 100%;
  height: 40%;
}

@mixin infoContent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 33.3%;
  height: 100%;
  margin-right: 1%;
  padding-right: 3%;
}

@mixin infoTitle {
  font-size: 1.12rem;
  // text-align: center;
  color: #ffffff;
  line-height: 1.3rem;
}

@mixin infoCount {
  font-size: 5rem;
  text-align: center;
  color: #ffffff;
  line-height: 6.6rem;
}
// 三個長方形裡的照片
@mixin infoImg {
  display: flex;
  width: 40%;
  // margin: auto;
  flex-direction: column;
  margin-top: 3%;
}

#header {
  width: 100%;
  height: 9.5%;
  .container {
    display: flex;
    height: 100%;
    background-color: #ffffff;
  }
}

.name {
  display: block;
  width: 10%;
  margin: auto 8% auto 1%;
  font-size: 2.25rem;
  font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
  color: #0081b1;
  @media (max-width: 1440px) {
    margin: auto 5% auto 1%;
  }
}
.time_container {
  height: 100%;
  width: 13%;
  margin: 0.5% 1% auto auto;
  height: 100%;
  .time {
    @include timeStyle;
  }
  .date {
    @include timeStyle;
  }
  .line {
    width: 100%;
    height: 20%;
    height: 1px;
    background: #efc54a;
  }
}

.info_container {
  display: flex;
  width: 54.5%;
  .all {
    @include infoContent;
    background: #043c78;
    > :nth-child(1) {
      @include infoImg;
      p {
        @include infoTitle;
      }
      img {
        @include infoImg;
      }
    }
    span {
      @include infoCount;
    }
  }
  .note {
    @include infoContent;
    background: #6eaabb;
    > :nth-child(1) {
      @include infoImg;
      p {
        @include infoTitle;
      }
      img {
        @include infoImg;
      }
    }
    span {
      @include infoCount;
    }
  }
  .local {
    @include infoContent;
    background: #89ad15;
    > :nth-child(1) {
      @include infoImg;
      p {
        @include infoTitle;
      }
      img {
        @include infoImg;
      }
    }
    span {
      @include infoCount;
    }
  }
}
.logo {
  display: flex;
  align-items: center;
  width: 20.7%;
  padding-left: 3%;
  img {
    width: 72.2%;
  }
}
</style>
