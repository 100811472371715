const tw = {
  header: {
    //
    totalNumberOfOperations: "總刀數",
    anesthesia: "上麻",
    localAnesthesia: "局麻",
    operatingRoom: "手術室",
    OpCountApiError: "獲取手術室信息列表失敗，請檢查網絡."
  },
  detailFooter: {
    anaesNote: "麻醉註記",
    medicalDevice: "醫材",
    medicalMaterials: "衛材",
    schedule: "手術排程"
  },
  footer: {
    dayShift: "白班",
    night: "小夜",
    bigNight: "大夜",
    maneuverable: "機動",
    filter: "條件檢索",
    alreadyFirstPage: "已經是第一頁啦！",
    alreadyLastPage: "已經是最後一頁啦！"
  },
  duty: {
    flow: "流動",
    brushHands: "刷手",
    specializedSurgery: "手術專責",
    dayShiftApiError: "獲取白班信息失敗，網絡錯誤."
  },
  notice: {
    auditNoPassed: "審核不通過",
    important: "重要",
    noticeApiError: "獲取手術室信息列表失敗，請檢查網."
  },
  room: {
    departmentName: "科別",
    operationType: "刀種",
    operationRoomID: "手術房號",
    scrubNurse: "刷手",
    flow: "流動",
    determine: "確 認",
    surgeon: "主刀",
    surgical: "術式",
    anaesDoctor: "麻醫",
    regularKnife: "常規刀",
    emergencyKnife: "急診刀",
    sharpKnife: "急刀",
    gastroenterology: "胃腸科",
    generalSurgery: "一般外科",
    neuroSurgery: "神經外科",
    urology: "泌尿外科",
    orthopedics: "骨科",
    plasticSurgery: "整形外科",
    thoracicSurgery: "胸腔外科",
    pediatricSurgery: "小兒外科",
    cardiovascularSurgery: "心血管外科",
    obstetricsAndGynecology: "婦產科",
    dentistry: "牙科",
    oralAndMaxillofacialSurgery: "口腔顎面外科",
    ophthalmology: "眼科",
    otolaryngology: "耳鼻喉科",
    anesthesiaDepartment: "麻醉科",
    loading: "資料載入中",
    roomFindByParamAPIError: "篩選手術室信息列表失敗，請檢查網絡.",
    getRoomFindByParamAPIError: "獲取手術室信息列表失敗，請檢查網絡.",
    toBeArranged: "待安排",
    waitingForPatients: "等候病患",
    EnterOperatingRoom: "進入術房",
    AnesthesiaPrelude: "麻醉前導",
    Scored: "已劃刀",
    BeforeClosingWound: "關閉傷口前",
    AfterClosingWound: "關閉傷口後",
    LeaveOperatingRoom: "離開術房",
    hasNotStarted: "未開始",
    waitingRoom: "等候室",
    enterOR: "入OR",
    signIn: "Sign in",
    timeOut: "Time out",
    signOut: "Sign out",
    cancel: "取消",
    finish: "完成",
    regularKnifeRow: "排刀(常規)",
    generalEmergencyKnife: "一般急刀(急診刀)",
    insertKnife: "插刀",
    superEmergencySurgery: "777",
    urgentKnife: "緊急刀",
    beautyKnife: "美容刀",
    delete: "刪除"
  },
  patient: {
    operationName: "手術",
    departmentName: "科別",
    operationType: "刀種",
    operationRoomID: "手術房號",
    doctor: "主刀醫生",
    primaryNurse: "專責",
    operationStart: "手術預計開始時間",
    patientStatus: "患者狀態",
    determine: "確 認",
    regularKnife: "常規刀",
    emergencyKnife: "急診刀",
    sharpKnife: "急刀",
    gastroenterology: "胃腸科",
    generalSurgery: "一般外科",
    neuroSurgery: "神經外科",
    urology: "泌尿外科",
    orthopedics: "骨科",
    plasticSurgery: "整形外科",
    thoracicSurgery: "胸腔外科",
    pediatricSurgery: "小兒外科",
    cardiovascularSurgery: "心血管外科",
    obstetricsAndGynecology: "婦產科",
    dentistry: "牙科",
    oralAndMaxillofacialSurgery: "口腔顎面外科",
    ophthalmology: "眼科",
    otolaryngology: "耳鼻喉科",
    anesthesiaDepartment: "麻醉科",
    operationInfoApiError: "篩選手術室信息列表失敗，請檢查網絡.",
    liquid: "液",
    touch: "接觸",
    call: "呼",
    foam: "沫",
    infect: "傳染",
    other: "其他",
    ownExpense: "自費",
    VIP: "VIP",
    drugSensitivity: "藥敏",
    regularKnifeRow: "排刀(常規)",
    generalEmergencyKnife: "一般急刀(急診刀)",
    insertKnife: "插刀",
    superEmergencySurgery: "777",
    urgentKnife: "緊急刀",
    beautyKnife: "美容刀",
    delete: "刪除"
  },
  detail: {
    birthdate: "出生日期",
    doctor: "主刀醫生",
    operationName: "手術名稱",
    specialNote: "照護註記",
    itemsOwnExpense: "自費品項",
    thinking: "考慮中",
    medicalHistory: "病史",
    healthInsuranceAnesthesiaDepth: "健保麻醉深度",
    difficultIntubation: "困難插管",
    identity: "身份別",
    surgicalInstruments: "手術器械",
    quantity: "數量",
    no: "第",
    page: "頁",
    total: "共",
    patientApiError: "獲取病患資訊失敗，請檢查網絡.",
    liquid: "液",
    touch: "接觸",
    call: "呼",
    foam: "沫",
    infect: "傳染",
    other: "其他",
    ownExpense: "自費",
    VIP: "VIP",
    drugSensitivity: "藥敏",
    swordSequence: "刀序",
    patient: "病人",
    department: "科別",
    technique: "術式",
    chiefSurgeon: "主刀醫師",
    OpSortError: "獲取刀序茲資訊失敗，請檢查網絡.",
    toBeArranged: "待安排",
    waitingForPatients: "等候病患",
    EnterOperatingRoom: "進入術房",
    AnesthesiaPrelude: "麻醉前導",
    Scored: "已劃刀",
    BeforeClosingWound: "關閉傷口前",
    AfterClosingWound: "關閉傷口後",
    LeaveOperatingRoom: "離開術房",
    hasNotStarted: "未開始",
    waitingRoom: "等候室",
    enterOR: "入OR",
    signIn: "Sign in",
    timeOut: "Time out",
    signOut: "Sign out",
    cancel: "取消",
    finish: "完成",
  }
};

export default tw;
