const tw = {
  header: {
    //
    totalNumberOfOperations: "総カット数",
    anesthesia: "上麻",
    localAnesthesia: "局所麻酔",
    operatingRoom: "手術室",
    OpCountApiError: "獲取手術室信息列表失敗，請檢查網絡."
  },
  detailFooter: {
    anaesNote: "麻醉註記",
    medicalDevice: "醫材",
    medicalMaterials: "衛材",
    schedule: "手術排程"
  },
  footer: {
    dayShift: "白班",
    night: "小夜",
    bigNight: "大夜",
    maneuverable: "機動",
    filter: "條件檢索",
    alreadyFirstPage: "已經是第一頁啦！",
    alreadyLastPage: "已經是最後一頁啦！"
  },
  duty: {
    flow: "モバイルナース",
    brushHands: "ハンドブラッシング看護師",
    specializedSurgery: "手術專責",
    dayShiftApiError: "獲取白班信息失敗，網絡錯誤."
  },
  notice: {
    auditNoPassed: "審核不通過",
    important: "重要",
    noticeApiError: "獲取手術室信息列表失敗，請檢查網."
  },
  room: {
    departmentName: "部門",
    operationType: "ナイフの種類",
    operationRoomID: "手術室番号",
    scrubNurse: "ハンドブラッシング看護師",
    flow: "モバイルナース",
    determine: "確 認",
    surgeon: "主刀",
    surgical: "技術",
    anaesDoctor: "麻酔科医",
    regularKnife: "常規刀",
    emergencyKnife: "緊急ナイフ",
    sharpKnife: "第二レベルの緊急事態",
    gastroenterology: "胃腸科",
    generalSurgery: "一般外科",
    neuroSurgery: "脳神経外科",
    urology: "泌尿器科",
    orthopedics: "整形外科",
    plasticSurgery: "形成外科",
    thoracicSurgery: "胸部外科",
    pediatricSurgery: "小児外科",
    cardiovascularSurgery: "心臓血管外科",
    obstetricsAndGynecology: "産婦人科医",
    dentistry: "歯科",
    oralAndMaxillofacialSurgery: "口腔顎顔面外科",
    ophthalmology: "眼科",
    otolaryngology: "耳鼻科",
    anesthesiaDepartment: "麻酔科",
    loading: "資料載入中",
    roomFindByParamAPIError: "篩選手術室信息列表失敗，請檢查網絡.",
    getRoomFindByParamAPIError: "獲取手術室信息列表失敗，請檢查網絡.",
    toBeArranged: "待安排",
    waitingForPatients: "等候病患",
    EnterOperatingRoom: "進入術房",
    AnesthesiaPrelude: "麻醉前導",
    Scored: "已劃刀",
    BeforeClosingWound: "關閉傷口前",
    AfterClosingWound: "關閉傷口後",
    LeaveOperatingRoom: "離開術房",
    hasNotStarted: "未開始",
    waitingRoom: "等候室",
    enterOR: "入OR",
    signIn: "Sign in",
    timeOut: "Time out",
    signOut: "Sign out",
    cancel: "取消",
    finish: "完成",
    regularKnifeRow: "ナイフ列（レギュラー）",
    generalEmergencyKnife: "一般急刀(急診刀)",
    insertKnife: "ナイフを入れる",
    superEmergencySurgery: "超緊急手術",
    urgentKnife: "緊急ナイフ",
    beautyKnife: "美容刀",
    delete: "消去"
  },
  patient: {
    operationName: "手術",
    departmentName: "部門",
    operationType: "ナイフの種類",
    operationRoomID: "手術室番号",
    doctor: "外科医長",
    primaryNurse: "專責",
    operationStart: "手術預計開始時間",
    patientStatus: "患者狀態",
    determine: "確 認",
    regularKnife: "常規刀",
    emergencyKnife: "緊急ナイフ",
    sharpKnife: "第二レベルの緊急事態",
    gastroenterology: "胃腸科",
    generalSurgery: "一般外科",
    neuroSurgery: "脳神経外科",
    urology: "泌尿器科",
    orthopedics: "整形外科",
    plasticSurgery: "形成外科",
    thoracicSurgery: "胸部外科",
    pediatricSurgery: "小児外科",
    cardiovascularSurgery: "心臓血管外科",
    obstetricsAndGynecology: "産婦人科医",
    dentistry: "歯科",
    oralAndMaxillofacialSurgery: "口腔顎顔面外科",
    ophthalmology: "眼科",
    otolaryngology: "耳鼻科",
    anesthesiaDepartment: "麻酔科",
    operationInfoApiError: "篩選手術室信息列表失敗，請檢查網絡.",
    liquid: "液",
    touch: "接觸",
    call: "呼",
    foam: "沫",
    infect: "傳染",
    other: "其他",
    ownExpense: "自費",
    VIP: "VIP",
    drugSensitivity: "藥敏",
    regularKnifeRow: "ナイフ列（レギュラー）",
    generalEmergencyKnife: "一般急刀(急診刀)",
    insertKnife: "ナイフを入れる",
    superEmergencySurgery: "超緊急手術",
    urgentKnife: "緊急ナイフ",
    beautyKnife: "美容刀",
    delete: "消去"
  },
  detail: {
    birthdate: "出生日期",
    doctor: "外科医長",
    operationName: "手術名稱",
    specialNote: "照護註記",
    itemsOwnExpense: "自費品項",
    thinking: "考慮中",
    medicalHistory: "病史",
    healthInsuranceAnesthesiaDepth: "健保麻醉深度",
    difficultIntubation: "困難插管",
    identity: "身份別",
    surgicalInstruments: "手術器械",
    quantity: "量",
    no: "第",
    page: "頁",
    total: "共",
    patientApiError: "獲取病患資訊失敗，請檢查網絡.",
    liquid: "液",
    touch: "接觸",
    call: "呼",
    foam: "沫",
    infect: "傳染",
    other: "其他",
    ownExpense: "自費",
    VIP: "VIP",
    drugSensitivity: "藥敏",
    swordSequence: "刀序",
    patient: "病人",
    department: "部門",
    technique: "技術",
    chiefSurgeon: "外科医長",
    OpSortError: "獲取刀序茲資訊失敗，請檢查網絡.",
    toBeArranged: "待安排",
    waitingForPatients: "等候病患",
    EnterOperatingRoom: "進入術房",
    AnesthesiaPrelude: "麻醉前導",
    Scored: "已劃刀",
    BeforeClosingWound: "關閉傷口前",
    AfterClosingWound: "關閉傷口後",
    LeaveOperatingRoom: "離開術房",
    hasNotStarted: "未開始",
    waitingRoom: "等候室",
    enterOR: "入OR",
    signIn: "Sign in",
    timeOut: "Time out",
    signOut: "Sign out",
    cancel: "取消",
    finish: "完成"
  }
};

export default tw;
