<template>
  <div id="room-detail">
    <div class="side">
      <div class="room">
        <div
          class="state"
          v-if="detail.operationRoom"
          :class="{
            TSI: detail.operationRoom.checkStatus == '1', // Sign in
            TTO: detail.operationRoom.checkStatus == '2', // Time out
            TCI: detail.operationRoom.checkStatus == '3', // Check in
            TSO: detail.operationRoom.checkStatus == '4', // Check out
            w: detail.operationRoom.checkStatus == 'W', // Check out
            p:
              detail.operationRoom.checkStatus == 'WR' || // 等候室
              detail.operationRoom.checkStatus == 'IR' || // 入OR
              detail.operationRoom.checkStatus == 'SI' || // Sign in
              detail.operationRoom.checkStatus == 'TO' || // Time out
              detail.operationRoom.checkStatus == 'SO', // Sign out
            c: detail.operationRoom.checkStatus == 'C', // Check out
            y: detail.operationRoom.checkStatus == 'Y' // Check out
          }"
        >
          {{ detail.operationRoom.checkStatus | operationStatusMsg }}
        </div>
        <div
          class="tri"
          v-if="detail.operationRoom"
          :class="{
            triTSI: detail.operationRoom.checkStatus == '1', // Sign in
            triTTO: detail.operationRoom.checkStatus == '2', // Time out
            triTCI: detail.operationRoom.checkStatus == '3', // Check in
            triTSO: detail.operationRoom.checkStatus == '4', // Check out
            triW: detail.operationRoom.checkStatus == 'W', // Check out
            triP:
              detail.operationRoom.checkStatus == 'WR' || // 等候室
              detail.operationRoom.checkStatus == 'IR' || // 入OR
              detail.operationRoom.checkStatus == 'SI' || // Sign in
              detail.operationRoom.checkStatus == 'TO' || // Time out
              detail.operationRoom.checkStatus == 'SO', // Sign out
            triC: detail.operationRoom.checkStatus == 'C', // Check out
            triY: detail.operationRoom.checkStatus == 'Y' // Check out
          }"
        ></div>
        <div class="room-num" v-if="detail.operationRoom">
          {{ detail.operationRoom.operationRoomNum }}
        </div>
      </div>
      <div class="patient">
        <div
          class="gender"
          v-if="detail.patientinfo"
          :class="{
            F: detail.patientinfo.gender == '0', // 女
            M: detail.patientinfo.gender == '1' // 男
          }"
        >
          <div
            v-if="detail.patientinfo"
            class="gender_icon"
            :class="{
              fIcon: detail.patientinfo.gender == '0', // 女
              mIcon: detail.patientinfo.gender == '1' // 男
            }"
          ></div>
        </div>
        <!-- 沒有資料時才會顯示 -->
        <div v-if="detail.patientinfo == null" class="gender-dexfault"></div>
        <div class="patient-info">
          <div class="patient-num" v-if="detail.patientinfo">
            {{ hiddenCode(detail.patientinfo.inpatientNum) }}
          </div>
          <div class="name" v-if="detail.operationInfo">
            {{ detail.operationInfo.patientName }}
          </div>
          <div class="brithday">{{ $t("detail.birthdate") }}:</div>
          <div class="brithday" v-if="detail.patientinfo">
            {{ detail.patientinfo.birthdate }}
          </div>
        </div>
      </div>
      <div class="doctor">
        <div class="doctor-info">
          <div class="doctorbox">
            <span class="title">{{ $t("detail.doctor") }}：</span>
            <span class="doctor_name" v-if="detail.operationInfo">{{
              detail.operationInfo.doctorName
            }}</span>
          </div>
          <p class="title">{{ $t("detail.operationName") }}：</p>
          <p class="text" v-if="detail.operationInfo">
            {{ detail.operationInfo.operationName }}
          </p>
        </div>
      </div>
      <div class="special">
        <p>{{ $t("detail.specialNote") }}</p>
        <div class="op">
          <div
            class="box"
            v-for="item in detail.patientinfo
              ? filteredPrecaution(detail.patientinfo.precaution)
              : null"
            :key="item"
            :class="{
              one: item == 1,
              two: item == 2,
              three: item == 3,
              four: item == 4,
              five: item == 5,
              six: item == 6,
              seven: item == 7,
              eight: item == 8,
              nine: item == 9
            }"
          >
            {{ item | precautionMsg }}
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="container">
        <!--         <div class="table-box">
          
        </div> -->
        <div
          class="table-info"
          v-for="(item, index) in sortData"
          :key="index"
          style="border: 1px #3F3E3E dashed;"
          v-show="
            index < pageOption.pageSize * 5 &&
              index >= (pageOption.pageSize - 1) * 5
          "
        >
          <div class="table-sequence">
            <p>{{ $t("detail.swordSequence") }}</p>
            <p>{{ item.opSort }}</p>
          </div>
          <div class="table-name">
            <p>{{ $t("detail.patient") }}</p>
            <p>{{ item.patientName }}</p>
          </div>
          <div class="table-department">
            <p>{{ $t("detail.department") }}</p>
            <p>{{ item.typeMed }}</p>
          </div>
          <div class="table-operation">
            <p>{{ $t("detail.technique") }}</p>
            <p>{{ item.operationName }}</p>
          </div>
          <div class="table-doctor">
            <p>{{ $t("detail.chiefSurgeon") }}</p>
            <p>{{ item.doctorName }}</p>
          </div>
        </div>
        <!-- <div class="pagination">
          <span>第{{ pageOption.pageSize }}頁</span>
          <span> /共{{ pageOption.allPages }}頁</span>
        </div> -->
        <ul class="pagination_icon">
          <li
            v-for="index in pageOption.allPages"
            :key="index"
            :class="{ selected: pageOption.pageSize === index }"
          ></li>
        </ul>
        <img class="people" src="../assets/patient/people@2x.png" />
      </div>
      <DetailFooter
        :pageOption="pageOption"
        :operationTaps="operationTaps"
        :linkToPatient="linkToPatient"
        :linkToRoom="linkToRoom"
      ></DetailFooter>
    </div>
  </div>
</template>

<script>
import DetailFooter from "@/components/detail/DetailFooter.vue";
import i18n from "@/i18n/i18n";
// @ is an alias to /src
import { api } from "@/api/api.js";
export default {
  name: "RoomDetail",
  components: {
    DetailFooter
  },
  data() {
    return {
      // roomList: null,
      operationTaps: true,
      operationIndex: 1,
      pageOptions: true,
      pageOption: {
        // 翻页
        allPages: 1, // 总页数
        pageSize: 1 // 当前页码
      },
      linkToPatient: false, //點擊Ｘ到病人頁
      linkToRoom: true, //點擊Ｘ到手術室頁
      detail: {
        // id:1,
        // patientRoom: 'R1',
        // patientName: '林盈甄',
        // roomStatus: '02',
        // patientNum: '42856576',
        // brithday: '2021-05-19',
        // anvsDoctorName: '陳柏年',
        // operationName: 'Bipolar hemiarthroplasty',
        // gender: 'M',
        // note:"001,002,003,004",
        // imgs: [
        //   {
        //     name:'01',
        //     src:require('../assets/patient/op_icu@2x.png')
        //   },
        //   {
        //     name:'02',
        //     src:require('../assets/patient/op_777@2x.png')
        //   },
        //   {
        //     name:'03',
        //     src:require('../assets/patient/op_own@2x.png')
        //   },
        //   {
        //     name:'04',
        //     src:require('../assets/patient/op_touch@2x.png')
        //   },
        //   {
        //     name:'05',
        //     src:require('../assets/patient/op_777@2x.png')
        //   },
        //   {
        //     name:'06',
        //     src:require('../assets/patient/op_icu@2x.png')
        //   },
        // ]
      },
      sortData: []
    };
  },
  mounted() {},
  methods: {
    hiddenCode(value) {
      return `${value.substr(0, value.length - 4)}****`;
    },
    getDateStr(AddDayCount) {
      // 昨日、今日、明日
      var dd = new Date();
      dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
      var y = dd.getFullYear();
      var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1; //获取当前月份的日期
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      return y + "-" + m + "-" + d;
    },
    filteredPrecaution(precaution) {
      if (precaution) {
        return precaution.split(",");
      } else {
        return [];
      }
    },
    initRoomDetail() {
      // console.log(this.$route.params.roomId);
      var data = {
        roomId: this.$route.params.roomId
        // roomId: "`this.$route.params.roomId`",
      };
      console.log(data);
      this.$axios.post(api.getRoomDetail, data).then(
        res => {
          if (res.data.success) {
            this.detail = res.data.result;
            // this.pageOption.allPages = res.data.result.pages;
            console.log(this.detail);
          } else {
            alert(i18n.t("detail.patientApiError"));
          }
        },
        err => {
          alert(i18n.t("detail.patientApiError"));
        }
      );
    },
    initOpSort() {
      var id = {
        date: this.getDateStr(0),
        roomId: this.$route.params.roomId
      };
      this.$axios.post(api.getOpSort, id).then(
        res => {
          if (res.data.success) {
            this.sortData = res.data.result;
            // console.log(this.sortData)
            this.pageOption.allPages = Math.ceil(this.sortData.length / 5);
          } else {
            alert(i18n.t("detail.OpSortError"));
          }
        },
        err => {
          alert(i18n.t("detail.OpSortError"));
        }
      );
    }
  },
  computed: {},
  filters: {
    // statusMsg(status) {
    //   if (status == '01') {
    //     return ' Sign in'
    //   } else if (status == '02') {
    //     return 'Time out'
    //   } else if (status == '03') {
    //     return 'Check in'
    //   } else if (status == '04') {
    //     return 'Check out'
    //   } else if (status == '05') {
    //     return '未排刀'
    //   } else if (status == '06') {
    //     return '超時'
    //   } else if (status == '0˙') {
    //     return '緊急狀況'
    //   }
    // },
    operationStatusMsg(status) {
      if (status == "1") {
        return i18n.t("detail.toBeArranged");
      } else if (status == "2") {
        return i18n.t("detail.waitingForPatients");
      } else if (status == "3") {
        return i18n.t("detail.EnterOperatingRoom");
      } else if (status == "4") {
        return i18n.t("detail.AnesthesiaPrelude");
      } else if (status == "5") {
        return i18n.t("detail.Scored");
      } else if (status == "6") {
        return i18n.t("detail.BeforeClosingWound");
      } else if (status == "7") {
        return i18n.t("detail.AfterClosingWound");
      } else if (status == "8") {
        return i18n.t("detail.LeaveOperatingRoom");
      } else if (status == "W") {
        return i18n.t("detail.hasNotStarted");
      } else if (status == "WR") {
        return i18n.t("detail.waitingRoom");
      } else if (status == "IR") {
        return i18n.t("detail.enterOR");
      } else if (status == "SI") {
        return i18n.t("detail.signIn");
      } else if (status == "TO") {
        return i18n.t("detail.timeOut");
      } else if (status == "SO") {
        return i18n.t("detail.signOut");
      } else if (status == "C") {
        return i18n.t("detail.cancel");
      } else if (status == "Y") {
        return i18n.t("detail.finish");
      }
    },
    precautionMsg(precaution) {
      if (precaution == 1) {
        return i18n.t("detail.liquid");
      }
      if (precaution == 2) {
        return i18n.t("detail.touch");
      }
      if (precaution == 3) {
        return i18n.t("detail.call");
      }
      if (precaution == 4) {
        return i18n.t("detail.foam");
      }
      if (precaution == 5) {
        return i18n.t("detail.infect");
      }
      if (precaution == 6) {
        return i18n.t("detail.other");
      }
      if (precaution == 7) {
        return i18n.t("detail.ownExpense");
      }
      if (precaution == 8) {
        return i18n.t("detail.VIP");
      }
      if (precaution == 9) {
        return i18n.t("detail.drugSensitivity");
      }
    },
  },
  created() {
    this.initRoomDetail();
    this.initOpSort();
    // console.log(this.$route.params.roomId);
    // console.log(1);
  }
};
</script>
<style scoped lang="scss">
@mixin state {
  width: 10.2vh;
  height: 100%;
}
@mixin triangle {
  width: 0px;
  height: 0px;
  // 垂直置中and內縮
  margin: auto 0 auto -5px;
  border-style: solid;
  border-width: 20px 0px 20px 30px;
}
#room-detail {
  width: 100%;
  height: 100vh;
  display: flex;
  .side {
    height: 100%;
    width: 36.2%;
  }
  .content {
    display: flex;
    flex-direction: column;

    // 表格與footer填滿側邊欄剩下的
    flex-grow: 1;
    .container {
      display: flex;
      flex-direction: column;

      // 表格填滿footer剩下的
      flex-grow: 1;
    }
  }
}
// 房間（第一行）
.side {
  border-right: 1px solid #3f3e3e;
  box-sizing: border-box;
  .room {
    width: 100%;
    height: 10.2%;
    display: flex;
    border-bottom: 1px solid #3f3e3e;
    box-sizing: border-box;
    .state {
      opacity: 1;
      font-size: 2.25rem;
      font-family: Helvetica-Bold;
      text-align: center;
      color: #ffffff;
      line-height: 2.5rem;
      display: flex;
      align-items: center;
      @include state;
      background-color: #9d9d9d;
      border-bottom: 1px solid #9d9d9d;
    }
    .tri {
      @include triangle;
      border-color: transparent transparent transparent #9d9d9d;
    }
    // Sign-in
    .TSI {
      @include state;
      background-color: #2fb0c3;
      border-bottom: 1px solid #2fb0c3;
    }
    .triTSI {
      @include triangle;
      border-color: transparent transparent transparent #2fb0c3;
    }
    // Time out
    .TTO {
      @include state;
      background-color: #d69727;
      border-bottom: 1px solid #d69727;
    }
    .triTTO {
      @include triangle;
      border-color: transparent transparent transparent #d69727;
    }
    //Check in
    .TCI {
      @include state;
      background-color: #2e9ad3;
      border-bottom: 1px solid #2e9ad3;
    }
    .triTCI {
      @include triangle;
      border-color: transparent transparent transparent #2e9ad3;
    }
    //Sign out
    .TSO {
      @include state;
      background-color: #a31f24;
      border-bottom: 1px solid #a31f24;
    }
    .triTSO {
      @include triangle;
      border-color: transparent transparent transparent #a31f24;
    }
    .w {
      @include state;
      background-color: #2e608b;
      border-bottom: 1px solid #2e608b;
    }
    .triW {
      @include triangle;
      border-color: transparent transparent transparent #2e608b;
    }
    .p {
      @include state;
      background-color: #7ec3d6;
      border-bottom: 1px solid #7ec3d6;
    }
    .triP {
      @include triangle;
      border-color: transparent transparent transparent #7ec3d6;
    }
    .c {
      @include state;
      background-color: #d89876;
      border-bottom: 1px solid #d89876;
    }
    .triC {
      @include triangle;
      border-color: transparent transparent transparent #d89876;
    }
    .y {
      @include state;
      background-color: #1b5b6c;
      border-bottom: 1px solid #1b5b6c;
    }
    .triY {
      @include triangle;
      border-color: transparent transparent transparent #1b5b6c;
    }
  }
  .room-num {
    // 置中＆左邊間距
    margin: auto 0 auto 3.1%;
    font-size: 4.5rem;
    font-family: Segoe UI, Segoe UI-Bold;
    font-weight: 700;
    text-align: left;
    color: #206a88;
    line-height: 5.4rem;
  }
}
// 性別那行（第二行）
.side {
  .patient {
    display: flex;
    width: 100%;
    height: 23.5%;
    border-bottom: 1px solid #3f3e3e;
    box-sizing: border-box;
    .gender {
      width: 10.2vh;
      height: 100%;
      background-color: #9d9d9d;
    }
    .gender-default {
      width: 10.2vh;
      height: 100%;
      background-color: #9d9d9d;
    }
    .M {
      width: 10.2vh;
      height: 100%;
      background-color: #5270ab;
      .mIcon {
        background: url("../assets/patient/man@2x.png") no-repeat;
        background-size: 25%;
        height: 100%;
        background-position: center 20%;
      }
    }
    .F {
      width: 10.2vh;
      height: 100%;
      background-color: #d8bbcd;
      .fIcon {
        background: url("../assets/patient/female@2x.png") no-repeat;
        background-size: 25%;
        height: 100%;
        background-position: center 20%;
      }
    }
    .patient-info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 5.5%;
      .patient-num {
        font-size: 1.5rem;
        font-family: STHeitiTC-Medium;
        color: #303030;
        line-height: 1.81rem;
      }
      .name {
        font-size: 4.5rem;
        font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #206a88;
        line-height: 5.375rem;
      }
      .brithday {
        font-size: 2.25rem;
        font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
        font-weight: 700;
        color: #206a88;
        line-height: 2.68rem;
      }
    }
  }
}
.side {
  .doctor {
    width: 100%;
    height: 24%;
    border-bottom: 1px solid #3f3e3e;
    box-sizing: border-box;
    font-size: 2.25rem;
    font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
    font-weight: 700;
    line-height: 2.68rem;
    .doctor-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 5.5%;
      .title {
        color: #206a88;
      }
      .text {
        color: #303030;
      }
    }
  }
}
.side {
  .special {
    width: 100%;
    margin: 16px 0 0 5.5%;
    p {
      font-size: 2.25rem;
      font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
      font-weight: 700;
      line-height: 2.68rem;
      color: #206a88;
    }
    .op {
      display: flex;
      flex-wrap: wrap;
      .box {
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4rem;
        width: 4rem;
        background-color: #9d9d9d;
        color: white;
        border-radius: 50%;
        font-size: 1.625rem;
      }
      .one {
        background-color: #bf3230;
      }
      .two {
        background-color: #e28e38;
      }
      .three {
        background-color: #56a6e0;
      }
      .four {
        background-color: #89ad15;
      }
      .five {
        background-color: #8a9295;
      }
      .six {
        background-color: #1292be;
      }
      .seven {
        background-color: #418396;
      }
      .eight {
        background-color: #5e80c1;
      }
      .nine {
        background-color: #cc9baf;
      }
    }
    // img{
    //   margin: 32px 2.65% 0 0;
    //   display: inline-block;
    //   width: 10.6%;
    // }
  }
}

.content {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #efefef;
    padding-top: 25px;
    width: 100%;
    height: 16.1%;
    .table-info {
      width: 91%;
      height: 16.1%;
      z-index: 1;
      display: flex;
      align-items: center;
      // justify-content: space-around;
      margin-top: 21px;
      // 刀序
      div:nth-child(1) {
        width: 13%;
      }
      div:nth-child(2) {
        width: 15%;
      }
      div:nth-child(3) {
        width: 15%;
      }
      div:nth-child(4) {
        width: 42%;
      }
      div:nth-child(5) {
        width: 15%;
      }
      div:nth-child(1) {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        > :nth-child(1) {
          font-size: 1.5rem;
        }
        > :nth-child(2) {
          font-size: 1.875rem;
        }
      }
      // 除了刀緒
      div:not(:nth-child(1)) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // align-items:center;
        > :nth-child(1) {
          font-size: 1.5rem;
        }
        > :nth-child(2) {
          font-size: 1.875rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .table-operation {
          max-width: 20%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
// .pagination {
//   display: flex;
//   justify-content: center;
//   margin:20px 0;
//   font-size: 2rem;
//   height: 30px;
//   >:nth-child(1) {
//     color: #393939;
//   }
//   >:nth-child(2) {
//     color: #bdbebd;
//   }
// }
.people {
  height: 20%;
  position: absolute;
  z-index: 0;
  right: 4%;
  bottom: 19%;
}
.pagination_icon {
  display: flex;
  justify-content: center;
  li {
    width: 31px;
    height: 10px;
    background-color: #bdbebd;
    margin: 0px 10px 10px 10px;
  }
  li.selected {
    background-color: #393939;
  }
}
.timeline {
  font-weight: bold;
  font-size: 1.5rem;
  padding: 15px 0px;
}
</style>
