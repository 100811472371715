<template>
  <div id="app">
    <div id="nav">

    </div>
    <router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>
export default {
  name:'App',
  provide(){
    return {
      reload:this.reload
    }
  },
  data(){
    return {
      isRouterAlive:true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive =false
      this.$nextTick(function(){
        this.isRouterAlive =true
      })
    }
  }
}

</script>
<style lang="scss">
@font-face {
  font-family:  Microsoft JhengHei, Microsoft JhengHei-Bold;
  unicode-range: U+7db0, U+78A7, U+7B75; /* ASCII (碧筵綰) */
  font-style: normal;
  font-weight: bold;
  src: local('Yu Gothic'), local('MS Gothic'), local('Arial'), local('sans-serif');
}
/* 一般粗細的時候改回微軟正黑 */
@font-face {
  font-family: Microsoft JhengHei, Microsoft JhengHei-Bold;
  unicode-range: U+7db0, U+78A7, U+7B75; /* ASCII (碧筵綰) */
  font-style: normal;
  font-weight: normal;
  src: local('微軟正黑體'), local(' Microsoft JhengHei');
}



html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, body,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  margin: 0;
  padding: 0;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}


@media (min-width: 720px){
  :root {
      font-size: 6px;
  }
}
@media (min-width: 840px){
  :root {
      font-size: 7px;
  }
}
@media (min-width: 960px){
  :root {
      font-size: 8px;
  }
}
@media (min-width: 1080px){
  :root {
      font-size: 9px;
  }
}

@media (min-width: 1200px){
  :root {
      font-size: 10px;
  }
}
@media (min-width: 1320px){
  :root {
      font-size: 11px;
  }
}

@media (min-width: 1440px){
  :root {
      font-size: 12px;
  }
}
@media (min-width: 1680px){
  :root {
      font-size: 13px;
  }
}
@media (min-width: 1800px){
  :root {
      font-size: 14px;
  }
}
@media (min-width: 1920px){
  :root {
      font-size: 15px;
  }
}
@media (min-width: 2040px){
    :root {
      font-size: 16px;
  }
}


</style>
