<template>
  <div id="footer">
    <ul class="tab">
      <router-link
        tag="li"
        to="/"
        class="room"
        :class="{ roomSelected: tabIndex === 1 }"
      ></router-link>
      <router-link
        tag="li"
        to="/patient"
        class="patient"
        :class="{ patientSelected: tabIndex === 2 }"
      ></router-link>
      <router-link
        tag="li"
        to="/overall"
        class="overall"
        :class="{ overallSelected: tabIndex === 3 }"
      ></router-link>
      <router-link
        tag="li"
        to="/duty"
        class="duty"
        :class="{ dutySelected: tabIndex === 4 }"
      ></router-link>
      <router-link
        tag="li"
        to="/notice"
        class="notice"
        :class="{ noticeSelected: tabIndex === 5 }"
      ></router-link>
      <!-- <router-link tag="li" to="/maintain" class="maintain" :class="{ maintainSelected : tabIndex ===6 }"></router-link> -->
    </ul>
    <div class="option">
      <ul class="frequency" v-if="shifts">
        <li :class="{ selected: shiftsIndex === 1 }" @click="shiftsClick(1)">
          {{ $t("footer.dayShift") }}
        </li>
        <!-- <li :class="{ selected : shiftsIndex ===2 }" @click="shiftsClick(2)">{{ $t("footer.night") }}</li>
        <li :class="{ selected : shiftsIndex ===3 }" @click="shiftsClick(3)">{{ $t("footer.bigNight") }}</li>
        <li :class="{ selected : shiftsIndex ===4 }" @click="shiftsClick(4)">{{ $t("footer.maneuverable") }}</li> -->
      </ul>
      <ul class="switch" v-if="pageOptions">
        <li
          class="back"
          :class="{ backUn: pageOption.pageSize == 1 }"
          @click="preClick"
        ></li>
        <li
          class="next"
          :class="{
            nextUn:
              pageOption.pageSize == pageOption.allPages ||
              pageOption.allPages == 0
          }"
          @click="nextClick"
        ></li>
      </ul>
      <div
        class="retrieval"
        :class="{ retrievalSelected: searchContent }"
        v-if="retrievalState"
        @click="searchlClick()"
      >
        <div class="search" :class="{ searchSelected: searchContent }"></div>
        <div class="text">{{ $t("footer.filter") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n/i18n";

export default {
  name: "Bottom",
  props: {
    // 父子组件之间的通信
    tabIndex: {
      // tab栏索引
      type: Number,
      default: 1
    },
    shifts: {
      // 班次分类显隐
      type: Boolean,
      default: false
    },
    shiftsIndex: {
      // 班次索引
      type: Number,
      default: 1
    },
    retrievalState: {
      // 条件检索显隐
      type: Boolean,
      default: false
    },
    pageOption: {
      // 翻页
      type: Object,
      default: null
    },
    pageOptions: {
      // 条件检索显隐
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchContent: false // 是否显示条件检索的内容
    };
  },
  methods: {
    shiftsClick(index) {
      // 班次切换
      this.$emit("childShifts", index);
    },
    searchlClick() {
      // 显隐检索内容
      this.searchContent = !this.searchContent;
      this.$emit("childSearchContent", this.searchContent);
    },
    preClick() {
      if (this.pageOption.pageSize > 1) {
        this.pageOption.pageSize--;
        return;
      }
      if ((this.pageOption.pageSize = 1)) {
        alert(i18n.t("footer.alreadyFirstPage"));
        return;
      }
      if (this.pageOption.pageSize < 1) {
        alert(i18n.t("footer.alreadyFirstPage"));
        return;
      }
    },
    nextClick() {
      if (this.pageOption.pageSize < this.pageOption.allPages) {
        this.pageOption.pageSize++;
        return;
      }
      if ((this.pageOption.pageSize = this.pageOption.allPages)) {
        alert(i18n.t("footer.alreadyLastPage"));
        return;
      }
      if (this.pageOption.pageSize > this.pageOption.allPages) {
        alert(i18n.t("footer.alreadyLastPage"));
        return;
      }
    }
  },
  created() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#footer {
  width: 100%;
  height: 97px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}
// tab切換
#footer {
  .tab {
    height: 100%;
    display: flex;
    li {
      width: 97px;
      height: 97px;
      cursor: pointer;
    }
  }
}
.tab {
  .room {
    background: url(../assets/footer/tab/room@2x.png) no-repeat center;
    background-size: 53px 56px;
  }
  .roomSelected {
    background: url(../assets/footer/tab/room_selected@2x.png) no-repeat center;
    background-size: 100% 100%;
  }
  .patient {
    background: url(../assets/footer/tab/patient@2x.png) no-repeat center;
    background-size: 53px 56px;
  }
  .patientSelected {
    background: url(../assets/footer/tab/patient_selected@2x.png) no-repeat
      center;
    background-size: 100% 100%;
  }
  .overall {
    background: url(../assets/footer/tab/dashboard@2x.png) no-repeat center;
    background-size: 53px 56px;
  }
  .overallSelected {
    background: url(../assets/footer/tab/dashboard_selected@2x.png) no-repeat
      center;
    background-size: 100% 100%;
  }
  .duty {
    background: url(../assets/footer/tab/duty@2x.png) no-repeat center;
    background-size: 67px 49px;
  }
  .dutySelected {
    background: url(../assets/footer/tab/duty_selected@2x.png) no-repeat center;
    background-size: 100% 100%;
  }
  .notice {
    background: url(../assets/footer/tab/notice@2x.png) no-repeat center;
    background-size: 44px 39px;
  }
  .noticeSelected {
    background: url(../assets/footer/tab/notice_selected@2x.png) no-repeat
      center;
    background-size: 100% 100%;
  }
  .maintain {
    background: url(../assets/footer/tab/fixeditem@2x.png) no-repeat center;
    background-size: 100% 100%;
    // color: #0081b1;
    // background-size: 44px 39px;
    // font-size: 3em;
    // font-weight: bold;
    // line-height: 97px;
    // text-align: center;
  }
  .maintainSelected {
    // background-color: #0081b1;
    // color: #fff;
    background: url(../assets/footer/tab/fixeditem_selected@2x.png) no-repeat
      center;
    background-size: 100% 100%;
  }
}
//
#footer {
  .option {
    height: 100%;
    display: flex;
    align-items: center;
    // 排班
    .frequency {
      height: 100%;
      display: flex;
      align-items: center;
      li {
        width: 134px;
        height: 62px;
        line-height: 62px;
        text-align: center;
        font-size: 24px;
        color: #0081b1;
        font-weight: 600;
        margin-right: 17px;
        border: 1px solid #0081b1;
        border-radius: 12px;
        cursor: pointer;
      }
      // li.selected{
      //   background-color: #0081b1;
      //   color: #fff;
      // }
    }
    // 分頁
    .switch {
      width: 137px;
      height: 100%;
      margin: 0 30px 0 33px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        width: 62px;
        height: 62px;
        cursor: pointer;
      }
      li.back {
        background: url(../assets/footer/switch/back@2x.png) no-repeat center;
        background-size: 100% 100%;
      }
      li.backUn {
        background: url(../assets/footer/switch/back_un@2x.png) no-repeat center;
        background-size: 100% 100%;
      }
      li.next {
        background: url(../assets/footer/switch/next@2x.png) no-repeat center;
        background-size: 100% 100%;
      }
      li.nextUn {
        background: url(../assets/footer/switch/next_un@2x.png) no-repeat center;
        background-size: 100% 100%;
      }
    }
    // 篩選
    .retrieval {
      width: 185px;
      height: 62px;
      border: 1px solid #0081b1;
      box-sizing: border-box;
      border-radius: 12px;
      margin-right: 20px;
      overflow: hidden;
      display: flex;
      cursor: pointer;
      line-height: 62px;
      font-size: 24px;
      color: #0081b1;
      font-weight: 600;
      .search {
        height: 100%;
        width: 73px;
        background: url(../assets/footer/retrieval/search_icon@2x.png) no-repeat
          center;
        background-size: 47px 31px;
      }
      .searchSelected {
        background: url(../assets/footer/retrieval/search_icon_selected@2x.png)
          no-repeat center;
        background-size: 47px 31px;
      }
      .text {
        width: 112px;
        height: 100%;
      }
    }
    .retrievalSelected {
      background-color: #0081b1;
      color: #fff;
    }
  }
}
</style>
