<template>
  <div id="duty">
    <Header></Header>
    <div class="content">
      <el-card
        v-for="(room, index) in dayShiftShow()"
        :key="index"
        class="card"
      >
        <div class="roomName">{{ room.id_room }}</div>
        <div class="roomContent">
          <div style="height: 50%;">
            <el-row>
              <el-col :span="12"
                ><div class="roomContentTitle">
                  {{ $t("duty.flow") }}
                </div></el-col
              >
              <el-col :span="12"
                ><div class="roomContentTitle">
                  {{ $t("duty.brushHands") }}
                </div></el-col
              >
            </el-row>
            <el-row>
              <el-col :span="6"
                ><div class="roomContentName">{{ room.help01 }}</div></el-col
              >
              <el-col :span="6"
                ><div class="roomContentName">{{ room.help02 }}</div></el-col
              >
              <el-col :span="6"
                ><div class="roomContentName">{{ room.help11 }}</div></el-col
              >
              <el-col :span="6"
                ><div class="roomContentName">{{ room.help12 }}</div></el-col
              >
            </el-row>
          </div>
          <div style="height: 50%;">
            <el-row>
              <el-col :span="24"
                ><div class="roomContentTitle">
                  {{ $t("duty.specializedSurgery") }}
                </div></el-col
              >
            </el-row>
            <el-row>
              <el-col :span="6"
                ><div class="roomContentName">{{ room.help31 }}</div></el-col
              >
              <el-col :span="6"
                ><div class="roomContentName">{{ room.help32 }}</div></el-col
              >
            </el-row>
          </div>
        </div>
      </el-card>
      <ul class="pagination_icon" v-if="pageOption.allPages > 1">
        <li
          v-for="index in pageOption.allPages"
          :key="index"
          :class="{ selected: pageOption.pageSize === index }"
        ></li>
      </ul>
    </div>
    <!-- <div style="height:81vh;">

    </div> -->
    <!-- <ul class="content"content v-if="shiftsIndex != 4">
      <li class="group" v-if="shiftsIndex == 2 | shiftsIndex == 3">
        <span>值班組長</span>
        <span>{{ groupName }}</span>
      </li>
      <li class="room" :class="{ small : shiftsIndex == 3 }" v-for="(item,i) in shiftContent" :key="i" v-if="i < pageOption.pageSize*10 && i >= (pageOption.pageSize - 1)*10">
        小夜、大夜班是分組，用i+1顯示組別即可
        <p v-if="shiftsIndex == 2 | shiftsIndex == 3">{{ item.parentShift.operationRoomNum }}</p>
        白班手術室、機動是跟著手術室的，需要帶出組別
        <p v-if="shiftsIndex == 1 ">{{ item.parentShift.operationRoomNum }}</p>
        <div class="hand" v-for="(shift,index) in item.details" :key="index">
          <span>{{ shift.shiftContentType | shiftType }}</span>
          <ol>
            <li v-for="(member,idx) in shift.shiftContentValueList" :key="idx">{{ shift.shiftContentValueList[idx] }}</li>
          </ol>
        </div>
      </li>
    </ul>
    <ul class="content" v-if="shiftsIndex == 4">
      <li class="room" v-for="(item,i) in eatShiftContent" :key="i" v-if="i < pageOption.pageSize*10 && i >= (pageOption.pageSize - 1)*10">
        <p>{{ item.key }}</p>
        <p>機動</p>
        <ol class="maneuver">
          <li v-for="(member,idx) in item.value" :key="idx">{{ member }}</li>
        </ol>
      </li>
    </ul> -->
    <Footer
      :tabIndex="tabIndex"
      :pageOption="pageOption"
      :pageOptions="pageOptions"
      :shifts="shifts"
      :shiftsIndex="shiftsIndex"
    >
    </Footer>
    <!-- @childShifts="switchShift" -->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { api } from "@/api/api.js";
import i18n from "@/i18n/i18n";
import moment from "moment";
export default {
  name: "Duty",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      tabIndex: 4, // 底部tab栏的索引
      shifts: true, // 显示班次分类
      shiftsIndex: 1, // 当前班次
      pageOption: {
        // 翻页
        allPages: 1, // 总页数
        pageSize: 1 // 当前页码
      },
      pageOptions: true,
      // groupName: "王毓陽",
      // shiftContent: [], // 排班内容
      // otherShiftType: "", // 白班其他时，最后一个特殊情况的类别
      // otherShiftList: [], // 白班其他时，最后一个特殊情况的人员列表
      // shiftLength: Number,
      // eatShiftContent: [], // 接吃饭排班内容
      // groupName: "",
      // eatShiftContent: [],
      dayShiftContent: [], // 白班資料
      showData: [] //顯示資料
    };
  },
  mounted() {},
  methods: {
    // getDateStr(AddDayCount) {
    //   // 昨日、今日、明日 -1,0,1
    //   var dd = new Date();
    //   dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
    //   var y = dd.getFullYear();
    //   var m =
    //     dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1; //获取当前月份的日期
    //   var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
    //   return y + "-" + m + "-" + d;
    // },
    // switchShift(i) {
    //   // 班次切换
    //   this.shiftsIndex = i;
    //   this.pageOption.allPages = 1;
    //   this.pageOption.pageSize = 1;
    //   let time = moment().format("YYYY-MM-DD");
    //   if (i == 1) {
    //     this.initShiftDetail("手術", time);
    //   } else if (i == 2) {
    //     this.initShiftDetail("小夜班", time);
    //   } else if (i == 3) {
    //     this.initShiftDetail("大夜班", time);
    //   } else if (i == 4) {
    //     this.initEatShift(); //this.getDateStr(0)
    //   }
    // },
    // initShiftDetail(type, date) {
    //   // 获取排班列表
    //   this.$axios
    //     .post(api.getShiftDetail, { shiftType: type, shiftDate: date })
    //     .then(
    //       res => {
    //         if (res.data.success) {
    //           if (type == "手術") {
    //             // 当排班信息为“手術”时
    //             var dataShift = res.data.result;
    //             let length = dataShift.length - 1;
    //             for (let i = length; i >= 0; i--) {
    //               // 排除全空的排班
    //               var flag = false;
    //               for (let j = 0; j < dataShift[i].details.length; j++) {
    //                 if (
    //                   (dataShift[i].details[j].shiftContentValueList[0] &&
    //                     dataShift[i].details[j].shiftContentValueList[0] !=
    //                       "" &&
    //                     dataShift[i].details[j].shiftContentValueList[0] !=
    //                       " ") ||
    //                   (dataShift[i].details[j].shiftContentValueList[1] &&
    //                     dataShift[i].details[j].shiftContentValueList[1] !=
    //                       "" &&
    //                     dataShift[i].details[j].shiftContentValueList[1] != " ")
    //                 ) {
    //                   flag = !flag;
    //                   break;
    //                 }
    //               }
    //               if (!flag) {
    //                 dataShift.splice(i, 1);
    //               }
    //             }
    //             dataShift.map(shift => {
    //               // 排除全空的排班后，原details中有五项，第一项为科别，则需先去除掉。
    //               shift.details.map((d, i) => {
    //                 if (d.shiftContentType == "department") {
    //                   shift.details.splice(i, 1);
    //                 }
    //               });
    //             });
    //             this.shiftContent = dataShift;
    //           }

    //           // 白班手術室可以串接後if 改為 else if
    //           else if (type == "小夜班" || type == "大夜班") {
    //             var dataShift = res.data.result;
    //             let length = dataShift.length - 1;
    //             for (let i = length; i >= 0; i--) {
    //               // 排除全空的排班
    //               var flag = false;
    //               for (let j = 0; j < dataShift[i].details.length; j++) {
    //                 if (
    //                   (dataShift[i].details[j].shiftContentValueList[0] &&
    //                     dataShift[i].details[j].shiftContentValueList[0] !=
    //                       "" &&
    //                     dataShift[i].details[j].shiftContentValueList[0] !=
    //                       " ") ||
    //                   (dataShift[i].details[j].shiftContentValueList[1] &&
    //                     dataShift[i].details[j].shiftContentValueList[1] !=
    //                       "" &&
    //                     dataShift[i].details[j].shiftContentValueList[1] != " ")
    //                 ) {
    //                   flag = !flag;
    //                   break;
    //                 }
    //               }
    //               if (!flag) {
    //                 dataShift.splice(i, 1);
    //               }
    //             }
    //             this.shiftContent = [];
    //             dataShift.map(shift => {
    //               if (!(shift.details[0].shiftContentType == "組長")) {
    //                 this.shiftContent.push(shift);
    //               } else {
    //                 // console.log(shift.details[0].shiftContentValueList[0]);
    //                 this.groupName = shift.details[0].shiftContentValueList[0];
    //               }
    //             });
    //             // console.log(this.shiftContent)
    //           } else if (type == "其他") {
    //             // 当排班信息为“其他”时，最后一项比较特殊，需单独处理。
    //             var otherData = res.data.result;
    //             var otherShiftContent;
    //             this.shiftContent = otherData.splice(0, otherData.length - 1);
    //             otherShiftContent = otherData.splice(-1, 1);
    //             this.otherShiftType =
    //               otherShiftContent[0].details[0].shiftContentType;
    //             this.otherShiftList =
    //               otherShiftContent[0].details[0].shiftContentValueList;
    //           } else {
    //             this.shiftContent = res.data.result;
    //           }
    //           this.pageOption.allPages = Math.ceil(
    //             this.shiftContent.length / 10
    //           );
    //         } else {
    //           alert("獲取排班列表信息失敗.");
    //         }
    //       },
    //       err => {
    //         alert("獲取排班列表信息失敗.");
    //       }
    //     );
    // },
    // initEatShift() {
    //   // 获取機動
    //   let time = moment().format("YYYY-MM-DD");
    //   var eatData = {
    //     arrangementDate: time,
    //     arrangementType: "eat"
    //   };
    //   this.$axios.post(api.getEatShift, eatData).then(
    //     res => {
    //       if (res.data.success) {
    //         this.eatShiftContent = res.data.result[0];
    //         // 排序，需要先把Ｒ去掉
    //         this.eatShiftContent.sort((x, y) => {
    //           let a = x.key.slice(1);
    //           let b = y.key.slice(1);
    //           return a - b;
    //         });
    //         console.log(this.eatShiftContent);
    //         this.pageOption.allPages = Math.ceil(
    //           this.eatShiftContent.length / 15
    //         );
    //       } else {
    //         alert("獲取機動排班信息失敗，網絡錯誤.");
    //       }
    //     },
    //     err => {
    //       alert("獲取機動排班信息失敗，網絡錯誤.");
    //     }
    //   );
    // },
    // 獲取白班
    async initMoreOpHelper() {
      const data = {
        date: moment().format("YYYY-MM-DD")
      };
      await this.$axios.post(window.g.moreOpHelper, data).then(
        res => {
          console.log(res);
          if (res.data.Result == "T") {
            this.dayShiftContent = res.data.Data;
            // this.showData = this.dayShiftShow()
            this.pageOption.allPages = Math.ceil(
              this.dayShiftContent.length / 10
            );
          } else {
            alert(i18n.t("duty.dayShiftApiError"));
          }
        },
        err => {
          alert(i18n.t("duty.dayShiftApiError"));
        }
      );
    },
    dayShiftShow() {
      console.log(
        this.pageOption,
        (this.pageOption.pageSize - 1) * 10,
        this.dayShiftContent.length
      );
      if (this.pageOption.pageSize * 10 < this.dayShiftContent.length) {
        return this.dayShiftContent.slice(
          (this.pageOption.pageSize - 1) * 10,
          (this.pageOption.pageSize - 1) * 10 + 10
        );
      } else {
        return this.dayShiftContent.slice(
          (this.pageOption.pageSize - 1) * 10,
          this.dayShiftContent.length
        );
      }
    }
  },
  // filters: {
  //   shiftType(type) {
  //     if (type == "flow") {
  //       return "流動";
  //     } else if (type == "hand") {
  //       return "刷手";
  //     } else if (type == "operation") {
  //       return "手術專職";
  //     } else if (type == "practice") {
  //       return "實務訓練";
  //     } else {
  //       return type;
  //     }
  //   }
  // },
  async created() {
    // let time = moment().format("YYYY-MM-DD");
    // this.initShiftDetail("手術", time);
    await this.initMoreOpHelper();
  }
};
</script>
<style scoped lang="scss">
// #duty{
//   width: 100%;
//   height: 100vh;
//   background: #efefef;
//   ul.content{
//     height: calc(100% - 9.5% - 97px);
//     display: flex;
//     flex-wrap: wrap;
//     align-content: flex-start;
//     width: 100%;
//     li.room{
//       width: 18.75%;
//       height: 28.5%;
//       // width: 360px;
//       // height: 251px;
//       background: #ffffff;
//       margin: 20px 0 0 20px;
//       display: flex;
//       flex-wrap: wrap;
//       align-content: flex-start;
//       @media (max-height: 920px) {
//         margin: 5px 0 0 5px;
//       }
//       @media (max-height: 1000px) {
//         margin: 10px 0 0 10px;
//       }
//       p{
//         width: 100%;
//         height: 33.1%;
//         // height: 83px;
//        // line-height: 84px;
//         line-height: 5.1875rem;
//         // font-size: 47px;
//         font-size: 2.9375rem;
//         color: #206a88;
//         font-weight: 600;
//         padding-left: 17px;
//         box-sizing: border-box;
//       }
//       p:nth-child(2) {
//         font-size: 22px;
//         color: #777776;
//         font-weight: 600;
//         height: 32px;
//         line-height: 32px;
//       }
//     }
//     li.group {
//       width: 100%;
//       height: 45px;
//       line-height: 65px;
//       padding-left: 20px;
//       font-size: 22px;
//       color: #777776;
//       box-sizing: border-box;
//       font-weight: 600;
//       span:nth-child(2) {
//         margin-left: 20px;
//         color: #206a88;
//       }
//     }
//     li.small {
//       margin: 20px 0 0 20px;
//     }
//   }
// }
// .content > li.small {
//   margin: 0 0 20px 20px;
// }
// .content > li.room > .hand {
//   width: 50%;
//   height: 33.5%;
//   // height: 74px;
//   display: flex;
//   flex-wrap: wrap;
// }
// .hand span {
//   display: inline-block;
//   width: 100%;
//   // height: 32px;
//   height: 38%;
//   font-size: 1.375rem;
//   color: #777776;
//   font-weight: 600;
//   padding-left: 17px;
//   box-sizing: border-box;
// }

// .hand ol {
//   width: 100%;
//   // height: 52px;
//   height: 70%;
//   // font-size: 22px;
//   font-size: 1.375rem;
//   color: #206a88;
//   font-weight: 600;
//   padding-left: 17px;
//   box-sizing: border-box;
//   display: flex;
// }
// .hand ol li {
//   width: 50%;
//   height: 100%;
//   line-height: 3.25rem;
//   box-sizing: border-box;
//   overflow: hidden;
// }

// .pagination_icon{
//   display: flex;
//   margin: 20px auto 0 auto;
//   li{
//     width: 31px;
//     height: 10px;
//     background-color: #bdbebd;
//     margin: 10px;
//   }
//   li.selected{
//     background-color: #393939;
//   }
// }
#duty {
  width: 100%;
  height: 100vh;
  background: #efefef;
  // ul.content {
  //   height: calc(100% - 9.5% - 97px);
  //   display: flex;
  //   flex-wrap: wrap;
  //   align-content: flex-start;
  //   width: 100%;
  //   li.room {
  //     width: 18.75%;
  //     height: 44%;
  //     // width: 360px;
  //     // height: 251px;
  //     background: #ffffff;
  //     margin: 20px 0 0 20px;
  //     display: flex;
  //     flex-wrap: wrap;
  //     align-content: flex-start;
  //     @media (max-height: 920px) {
  //       margin: 5px 0 0 5px;
  //     }
  //     @media (max-height: 1000px) {
  //       margin: 10px 0 0 10px;
  //     }
  //     p {
  //       width: 100%;
  //       // height: 33.1%;
  //       // line-height: 84px;
  //       line-height: 5.1875rem;
  //       // font-size: 47px;
  //       font-size: 2.9375rem;
  //       color: #206a88;
  //       font-weight: 600;
  //       padding-left: 17px;
  //       box-sizing: border-box;
  //     }
  //     p:nth-child(2) {
  //       font-size: 22px;
  //       color: #777776;
  //       font-weight: 600;
  //       height: 32px;
  //       line-height: 32px;
  //     }
  //   }
  //   li.group {
  //     width: 100%;
  //     height: 45px;
  //     line-height: 65px;
  //     padding-left: 20px;
  //     font-size: 22px;
  //     color: #777776;
  //     box-sizing: border-box;
  //     font-weight: 600;
  //     span:nth-child(2) {
  //       margin-left: 20px;
  //       color: #206a88;
  //     }
  //   }
  // }
}
// .content > li.small {
//   margin: 0 0 20px 20px;
// }
// .content > li.room > .hand {
//   width: 50%;
//   height: 33.5%;
//   // height: 74px;
//   display: flex;
//   flex-wrap: wrap;
// }
// .hand span {
//   display: inline-block;
//   width: 100%;
//   // height: 32px;
//   height: 16.5%;
//   font-size: 1.375rem;
//   color: #777776;
//   font-weight: 600;
//   padding-left: 17px;
//   box-sizing: border-box;
// }

// .hand ol {
//   width: 100%;
//   // height: 52px;
//   height: 70%;
//   // font-size: 22px;
//   font-size: 1.375rem;
//   color: #206a88;
//   font-weight: 600;
//   padding-left: 17px;
//   box-sizing: border-box;
//   display: flex;
//   flex-wrap: wrap;
// }
// .hand ol li {
//   width: 50%;
//   box-sizing: border-box;
//   overflow: hidden;
// }

// .pagination_icon {
//   display: flex;
//   margin: 20px auto 0 auto;
//   li {
//     width: 31px;
//     height: 10px;
//     background-color: #bdbebd;
//     margin: 10px;
//   }
//   li.selected {
//     background-color: #393939;
//   }
// }

// .content > li.room > .hand {
//   width: 50%;
//   height: 33.5%;
//   // height: 74px;
//   display: flex;
//   flex-wrap: wrap;
// }
// .hand span {
//   display: inline-block;
//   width: 100%;
//   // height: 32px;
//   height: 16.5%;
//   font-size: 1.375rem;
//   color: #777776;
//   font-weight: 600;
//   padding-left: 17px;
//   box-sizing: border-box;
// }

// .hand ol {
//   width: 100%;
//   // height: 52px;
//   height: 70%;
//   // font-size: 22px;
//   font-size: 1.375rem;
//   color: #206a88;
//   font-weight: 600;
//   padding-left: 17px;
//   box-sizing: border-box;
//   display: flex;
//   flex-wrap: wrap;
// }
// .hand ol li {
//   width: 50%;
//   box-sizing: border-box;
//   overflow: hidden;
// }

// // 機動
// .room .maneuver {
//   width: 100%;
//   height: 135px;
//   overflow: hidden;
// }
// .room .maneuver > li {
//   box-sizing: border-box;
//   width: 50%;
//   line-height: 52px;
//   font-size: 22px;
//   color: #206a88;
//   font-weight: 600;
//   float: left;
// }
// .room .maneuver > li:nth-child(1),
// .room .maneuver > li:nth-child(3) {
//   padding-left: 17px;
// }
.content {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  height: 78%;
  justify-content: center;
}
.content .card {
  width: 19%;
  height: 35vh;
  margin-left: 0.5rem;
  margin-right: 0.4rem;
  margin-bottom: 1.5rem;
}
.el-card /deep/ .el-card__body {
  height: 75%;
}
.content .card .roomName {
  height: 10%;
  font-size: 2.5rem;
  color: #206a88;
  font-weight: bold;
}
.content .card .roomContent {
  height: 100%;
  margin-top: 30px;
}
.content .card .roomContentTitle {
  font-size: 1.2rem;
  font-weight: bold;
  color: #777776;
}
.content .card .roomContentName {
  margin-top: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #206a88;
}
.pagination_icon {
  display: flex;
  justify-content: center;
  li {
    width: 31px;
    height: 10px;
    background-color: #bdbebd;
    margin: 0px 10px 10px 10px;
  }
  li.selected {
    background-color: #393939;
  }
}
</style>
