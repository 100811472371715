<template>
  <div id="notice">
    <Header></Header>
    <div class="container">
      <ul class="content">
        <li v-for="item in noticeList" :key="item.id">
          <div class="top">
            <span class="time">{{ item.gmtCreate | gmtCreateFormat }}</span>
            <div class="important" v-show="item.contentType == '重要公告'">
              <span class="hint" :title="$t('notice.auditNoPassed')">!</span>
              <span class="text"> {{ $t("notice.important") }}</span>
            </div>
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="details" v-html="item.content"></div>
        </li>
      </ul>
      <ul class="pagination_icon">
        <li
          v-for="index in pageOption.allPages"
          :key="index"
          :class="{ selected: pageOption.pageSize === index }"
        ></li>
      </ul>
    </div>
    <Footer
      :pageOptions="pageOptions"
      :tabIndex="tabIndex"
      :pageOption="pageOption"
    ></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { api } from "@/api/api.js";

export default {
  name: "Notice",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      tabIndex: 5,
      pageOption: {
        // 翻页
        allPages: 2, // 总页数
        pageSize: 1 // 当前页码
      },
      pageOptions: true,
      noticeList: [
        // {
        // id: 1,
        // gmtCreate: '2021-04-07 10:50:10',
        // title: '台灣社區已相對安全，放寬精神科病房探視，各行各業依風險條件逐步調整',
        // content: "實地探視採預約制，且實名(聯)登錄管理探視者的個人資料。訪客配合院方量體溫、手部清潔、詢問旅遊接觸史等相關感管措施。每位病人1天限探視1次，且同一時間同一探視空間原則限1組訪客，每組訪客人數最多2人，訪客與病人全程都須佩戴口罩。實地探視採預約制，且實名(聯)登錄管理探視者的個人資料。"
        // },
        // {
        // id: 2,
        // gmtCreate: '2021-04-07 10:50:10',
        // title: '台灣社區已相對安全，放寬精神科病房探視，各行各業依風險條件逐步調整',
        // content: "實地探視採預約制，且實名(聯)登錄管理探視者的個人資料。訪客配合院方量體溫、手部清潔、詢問旅遊接觸史等相關感管措施。每位病人1天限探視1次，且同一時間同一探視空間原則限1組訪客，每組訪客人數最多2人，訪客與病人全程都須佩戴口罩。"
        // },
        // {
        // id: 3,
        // gmtCreate: '2021-04-07 10:50:10',
        // title: '台灣社區已相對安全，放寬精神科病房探視，各行各業依風險條件逐步調整',
        // content: "實地探視採預約制，且實名(聯)登錄管理探視者的個人資料。訪客配合院方量體溫、手部清潔、詢問旅遊接觸史等相關感管措施。每位病人1天限探視1次，且同一時間同一探視空間原則限1組訪客，每組訪客人數最多2人，訪客與病人全程都須佩戴口罩。"
        // },
        // {
        // id: 4,
        // gmtCreate: '2021-04-07 10:50:10',
        // title: '台灣社區已相對安全，放寬精神科病房探視，各行各業依風險條件逐步調整',
        // content: "實地探視採預約制，且實名(聯)登錄管理探視者的個人資料。訪客配合院方量體溫、手部清潔、詢問旅遊接觸史等相關感管措施。每位病人1天限探視1次，且同一時間同一探視空間原則限1組訪客，每組訪客人數最多2人，訪客與病人全程都須佩戴口罩。"
        // },
        // {
        // id: 5,
        // gmtCreate: '2021-04-07 10:50:10',
        // title: '台灣社區已相對安全，放寬精神科病房探視，各行各業依風險條件逐步調整',
        // content: "實地探視採預約制，且實名(聯)登錄管理探視者的個人資料。訪客配合院方量體溫、手部清潔、詢問旅遊接觸史等相關感管措施。每位病人1天限探視1次，且同一時間同一探視空間原則限1組訪客，每組訪客人數最多2人，訪客與病人全程都須佩戴口罩。"
        // }
      ]
    };
  },
  mounted() {},
  watch: {
    "pageOption.pageSize"() {
      this.whetherDilter ? this.enterClick() : this.initNotice();
    },
    "pageOption.allPages"() {
      // 改变时，让当前页码等于1
      this.pageOption.pageSize = 1;
    }
  },
  methods: {
    initNotice() {
      this.$axios
        .get(
          api.getNotice +
            "?pageNum=" +
            this.pageOption.pageSize +
            "&pageSize=" +
            5
        )
        .then(
          res => {
            if (res.data.success) {
              this.noticeList = res.data.result.list;
              this.pageOption.allPages = res.data.result.pages;
            } else {
              alert(i18n.t("notice.noticeApiError"));
            }
          },
          err => {
            alert(i18n.t("notice.noticeApiError"));
          }
        );
    }
  },
  filters: {
    // 過濾器
    gmtCreateFormat(msg) {
      // msg 为固定的参数 即是你需要过滤的数据
      if (msg) {
        let time = moment(msg).format("YYYY/MM/DD HH:mm");
        return time;
      }
    }
  },
  created() {
    this.initNotice();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
#notice {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: 1;
  background: #efefef;
}
.container {
  // 撐開header與footer剩下的位子
  width: 100%;
  height: calc(100% - 9.5% - 97px);
}
.content {
  width: 100%;
  height: 90%;
  display: flex;
  li {
    // height: 100%;
    background-color: #fff;
    margin: 18px 0 0 16px;
    padding: 0 15px;
    box-sizing: border-box;
    width: calc(100% / 5);
  }
}

li {
  // height: 100%;
  .top {
    width: 100%;
    height: 5.5%;
    margin-top: 3.5%;
    margin-bottom: 4%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .time {
      font-family: SegoeUI-Bold;
      font-size: 1.5rem;
      width: 78.5%;
    }
    .important {
      width: 21.5%;
      // height: 5.5%;
      opacity: 1;
      background: #e2574c;
      border-radius: 6px;
      margin-top: 3.5%;
      margin-bottom: 4%;
      height: 75%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 0.375rem;
      box-sizing: border-box;
      img {
        width: 21.5%;
      }
      .hint {
        font-family: Noto Sans CJK TC, Noto Sans CJK TC-Bold;
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        line-height: 1.25rem;
        border: 1px solid white;
        border-radius: 50%;
        color: white;
        text-align: center;
        cursor: default;
        @media (max-width: 1440px) {
          width: 0.75rem;
          height: 0.75rem;
          line-height: 0.75rem;
        }
        @media (max-width: 1560px) {
          width: 1rem;
          height: 1rem;
          line-height: 1rem;
        }
      }
      .text {
        opacity: 1;
        font-size: 1rem;
        font-family: Noto Sans CJK TC, Noto Sans CJK TC-Bold;
        font-weight: 700;
        text-align: left;
        color: #ffffff;
        line-height: 1.5rem;
        @media (max-width: 1440px) {
          font-size: 0.8rem;
          line-height: 1.2rem;
        }
        @media (max-width: 1560px) {
          font-size: 0.6rem;
          line-height: 1rem;
        }
      }
    }
  }
  .title {
    font-family: MicrosoftJhengHeiBold;
    font-size: 1.75rem;
    color: #206681;
    line-height: 2.125rem;
    width: 100%;
    height: 16.5%;
    @media (max-width: 1440px) {
      margin-bottom: 2%;
      line-height: 1.8rem;
    }
  }
  .details {
    font-family: MicrosoftJhengHeiBold;
    font-size: 1.75rem;
    color: #070203;
    line-height: 3rem;
    width: 100%;
    height: 68%;
    overflow: auto;
    @media (max-width: 1440px) {
      line-height: 2rem;
    }
  }
}
.pagination_icon {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  li {
    width: 31px;
    height: 10px;
    background-color: #bdbebd;
    margin: 10px;
  }
  li.selected {
    background-color: #393939;
  }
}
</style>
