<template>
  <div id="overall">
    <Header></Header>
      <iframe :src="overallUrl" frameborder="0" style="height: 98%; width: 100%;"></iframe>
    <Footer :tabIndex="tabIndex" :pageOptios="pageOptios"></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  inject:['reload'],
  name: 'Overall',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      overallUrl:'',
      tabIndex: 3, // 底部tab栏的索引
      // retrievalState: false, // 显示条件检索
      // searchContent: false, // 显示条件检索内容
      pageOptios: true,
    }
  },
  mounted(){
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods:{
    startTimer() {
      this.timer = setInterval(() => {
        this.reload()
      }, window.g.updateTime * 1000 * 60); 
    },
    stopTimer() {
      // 清除定時器
      clearInterval(this.timer);
    }
  },
  created() {
    if(window.g.language){
      this.overallUrl = `${window.g.overallUrl}?lang=${window.g.language}`
    }
  },
}
</script>
<style scoped lang="scss">
#overall{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: 1;
  background: #efefef;
  box-sizing: border-box;
  // background-color:#EFEFEF;

}
.container{
//   父元素設定display:flex;，子元素用flexgrow: 1;填滿除了header與footer剩下的區塊
    height: 75%;
    flex-grow: 1;
    max-width: 98%;
    // display: flex;
    // flex-direction: column;
    margin-top: 1%;
    padding: 0px 1%;
    ul.count{
    height: 17.5%;
    display: flex;
    justify-content: space-between;
    li{
      width: 13%;
      height: 100%;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      >:nth-child(1){
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-family: Helvetica, Helvetica-Bold;
      }
      >:nth-child(2){
        font-size: 5rem;
        line-height: 5rem;
        font-family: Helvetica, Helvetica-Bold;
      }
    }
  }
  .chart_container{
    display: flex;
    justify-content: space-between;    
    width: 100%;
    height: 80%;
    margin-top: 1.35%;
  }
}

.img-template{
  margin:28px; 
  height: 100%;
  background-image: url("../assets/奇美手術室IDB_總覽.png");
  background-size: cover;
}

</style>
