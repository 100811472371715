<template>
  <div id="maintain">
    <Header></Header>
      <iframe :src="maintainUrl" frameborder="0" style="height: 98%; width: 100%;" @load="iframeLoad"></iframe>
    <Footer :tabIndex="tabIndex" :pageOptios="pageOptios"></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  inject:['reload'],
  name: 'maintain',
  components: {
    Header,
    Footer
  },
  mounted(){
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },
  data() {
    return {
      maintainUrl:'',
      tabIndex: 6, // 底部tab栏的索引
      pageOptios: false,
    }
  },
  created() {
    if(window.g.language){
      this.maintainUrl = `${window.g.maintainUrl}?lang=${window.g.language}`
    }
  },
  methods: {
    iframeLoad: function () {
      console.log('Maintain iframe url is: ', this.maintainUrl)
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.reload()
      }, window.g.updateTime * 1000 * 60); 
    },
    stopTimer() {
      // 清除定時器
      clearInterval(this.timer);
    }
  },
}
</script>

<style scoped lang="scss">
#maintain{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: 1;
  background: #efefef;
  box-sizing: border-box;
}
</style>